/*
Theme Name: pyfone
Theme URI: http://underscores.me/
Author: Underscores.me
Author URI: http://underscores.me/
Description: Description
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: pyfone
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

pyfone is based on Underscores http://underscores.me/, (C) 2012-2015 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/
/* set your base font and uncomment

$base-font-size: 15px;

*/
/* font-family: "Helvetica  Roman"; */
@font-face {
  font-family: 'helveticaneuecyr-roman-webfont';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/helveticaneuecyr-roman-webfont.eot");
  src: url("../fonts/helveticaneuecyr-roman-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-roman-webfont.woff2") format("woff2"), url("../fonts/helveticaneuecyr-roman-webfont.woff") format("woff"), url("../fonts/helveticaneuecyr-roman-webfont.ttf") format("truetype"); }

/* font-family: "Helvetica  Light"; */
@font-face {
  font-family: 'helveticaneuecyr-light-webfont';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/helveticaneuecyr-light-webfont.eot");
  src: url("../fonts/helveticaneuecyr-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-light-webfont.woff2") format("woff2"), url("../fonts/helveticaneuecyr-light-webfont.woff") format("woff"), url("../fonts/helveticaneuecyr-light-webfont.ttf") format("truetype"); }

/* font-family: "Helvetica  Medium"; */
@font-face {
  font-family: 'helveticaneuecyrmedium';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/helveticaneuecyr-medium-webfont.eot");
  src: url("../fonts/helveticaneuecyr-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-medium-webfont.woff2") format("woff2"), url("../fonts/helveticaneuecyr-medium-webfont.woff") format("woff"), url("../fonts/helveticaneuecyr-medium-webfont.ttf") format("truetype"); }

/* font-family: "AllerRegular"; */
@font-face {
  font-family: 'AllerRegular';
  src: url("../fonts/aller_rg-webfont.eot");
  src: url("../fonts/aller_rg-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aller_rg-webfont.woff2") format("woff2"), url("../fonts/aller_rg-webfont.woff") format("woff"), url("../fonts/aller_rg-webfont.ttf") format("truetype"), url("../fonts/aller_rg-webfont.svg#allerregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* THis font for right arrow icon */
@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello.eot?22086859");
  src: url("../fonts/fontello.eot?22086859#iefix") format("embedded-opentype"), url("../fonts/fontello.woff?22086859") format("woff"), url("../fonts/fontello.ttf?22086859") format("truetype"), url("../fonts/fontello.svg?22086859#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* === Base styles === */
html, body {
  font-size: 16px; }

div, section, input[type="text"], input[type="email"], input[type="password"] {
  box-sizing: border-box; }

a {
  text-decoration: none; }

.clear:after {
  content: '';
  display: table;
  clear: both; }

.icon-right-fontello {
  font-size: 14px; }

.dl-btn {
  display: inline-block;
  width: 150px;
  height: 45px;
  margin-top: 18px;
  font-family: "helveticaneuecyr-medium", sans-serif;
  font-size: 18px;
  line-height: 46px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-image: -webkit-linear-gradient(to bottom, #000 0%, #000 100%);
  background-image: -moz-linear-gradient(to bottom, #000 0%, #000 100%);
  background-image: -o-linear-gradient(to bottom, #000 0%, #000 100%);
  background-image: linear-gradient(to bottom, #000 0%, #000 100%);
  border-radius: 9px; }
  .dl-btn:hover {
    background-image: -webkit-linear-gradient(to bottom, #313131 0%, #000 100%);
    background-image: -moz-linear-gradient(to bottom, #313131 0%, #000 100%);
    background-image: -o-linear-gradient(to bottom, #313131 0%, #000 100%);
    background-image: linear-gradient(to bottom, #313131 0%, #000 100%); }
  .dl-btn:active {
    background-image: -webkit-linear-gradient(to top, #313131 0%, #000 100%);
    background-image: -moz-linear-gradient(to top, #313131 0%, #000 100%);
    background-image: -o-linear-gradient(to top, #313131 0%, #000 100%);
    background-image: linear-gradient(to top, #313131 0%, #000 100%); }
  .dl-btn.android p {
    width: 100%;
    height: 100%;
    background: transparent url(../img/google-play.png) center no-repeat;
    background-size: 100%; }
  .dl-btn.ios p {
    width: 100%;
    height: 100%;
    background: transparent url(../img/btn_dl-ios.png) center no-repeat;
    background-size: 100%; }
  .dl-btn-outer {
    display: inline-block; }

.radio input {
  display: none; }

.radio label {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "helveticaneuecyr-light", sans-serif;
  color: #aaafae; }

.radio .circle {
  display: inline-block;
  margin-bottom: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #acacac;
  padding: 2px; }
  .radio .circle:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: tr; }

.radio input:checked + label {
  color: #000; }
  .radio input:checked + label .circle:before {
    background-color: #2d6d64; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-right-fontello:before {
  content: '\e800'; }

/* === Grid === */
.wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: hidden; }
  .wrapper:after {
    content: " ";
    display: block;
    clear: both; }
  .wrapper.cabinet {
    display: none; }

.wrap {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-width: 1000px;
  max-width: 1000px;
  padding-left: 15px;
  padding-right: 15px; }
  .wrap:after {
    content: " ";
    display: block;
    clear: both; }
  .wrap:after {
    content: '';
    display: table;
    clear: both; }

@media screen and (max-width: 1000px) {
  .wrap {
    min-width: 100%;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px; } }

/* === Header layout === */
.header {
  height: 100vh; }

/* === Main layout === */
/* === Footer layout === */
/* === Header module === */
.header {
  background-image: -webkit-linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);
  background-image: -moz-linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);
  background-image: -o-linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);
  background-image: linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%); }
  .header-top {
    padding: 36px 0; }
    @media screen and (max-width: 320px) {
      .header-top {
        padding: 1.953125vh 0; } }
  .header .wrap {
    position: relative; }
  .header > .wrap {
    height: calc(100% - 122px); }
  .header .logo {
    float: left;
    width: calc(17.8% + 6px);
    height: 62px;
    background: transparent url(../img/logo.png) 0 0 no-repeat; }
    .header .logo-box {
      float: left; }
  .header .top-slider {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .header .top-slider .slide-box > li {
      position: relative;
      height: calc(100vh - 122px); }
      .header .top-slider .slide-box > li img {
        max-height: calc(100vh - 122px - 100px); }
    .header .top-slider .slide-img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .header .top-slider .bullets {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%); }
      .header .top-slider .bullets li {
        float: left;
        margin: 0 6px;
        width: 8px;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        transition: background .3s; }
        .header .top-slider .bullets li.selected {
          background-color: white; }
  .header-content {
    width: 41.66667%;
    float: left;
    margin-left: 58.33333%;
    position: relative;
    height: 100%;
    color: #fff; }
    .header-content .inner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; }
    .header-content h1 {
      margin-top: 5.2vh;
      font-family: "AllerRegular", sans-serif;
      font-size: 60px; }
    .header-content .sub-title {
      margin-top: 3.25vh;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 21px; }
    .header-content .download {
      display: inline-block;
      margin: 5.2vh 0 3.25vh;
      padding: 17px 48px;
      font-family: "helveticaneuecyr-medium", sans-serif;
      font-size: 24px;
      color: #fff;
      text-transform: uppercase;
      background-color: #ffa726;
      border-radius: 9px; }
    .header-content .features li {
      padding: 3px 0;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 18px; }
    .header-content .bottom-text {
      margin-top: 11.71875vh;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      padding-top: 10px;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      opacity: .75; }
      .header-content .bottom-text p {
        padding-top: 10px;
        font-family: "helveticaneuecyr-roman", sans-serif;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        opacity: .75; }

.menu {
  float: left;
  width: calc(100% - 0px);
  text-align: justify; }
  .menu:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .menu-nav {
    width: 58.33333%;
    float: left;
    float: right;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%); }
    .menu-nav .lang {
      min-width: 30px;
      float: right;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 16px;
      display: none; }
      .menu-nav .lang .language-chooser {
        position: absolute;
        right: 0; }
        .menu-nav .lang .language-chooser:before {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 0;
          width: 6px;
          height: 6px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg); }
        .menu-nav .lang .language-chooser li {
          margin-bottom: 5px;
          display: none; }
        .menu-nav .lang .language-chooser span {
          display: inline-block;
          min-width: 30px;
          color: #fff; }
        .menu-nav .lang .language-chooser .active {
          display: block; }
  .menu-item {
    display: inline-block; }
    .menu-item a {
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 16px;
      color: #fff; }

.page .header {
  background-image: none;
  height: auto;
  position: relative;
  z-index: 1; }
  .page .header .logo {
    background: transparent url(../img/logo-green.png) 0 0 no-repeat; }

.page .menu-nav .lang .language-chooser span {
  display: inline-block;
  min-width: 30px;
  color: #000; }

.page .menu-nav .lang .language-chooser:before {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000; }

.page .menu-item a {
  color: #000; }

.page .menu-item.current-menu-item a {
  color: #2e7666; }

@media screen and (max-width: 1366px) and (max-height: 700px) {
  .header-content .bottom-text {
    margin-top: 2.75vh; } }

@media screen and (max-width: 1024px) {
  .header-content {
    margin-left: 57%; } }

@media screen and (max-width: 1000px) {
  .header {
    text-align: center; }
    .header > .wrap {
      padding: 0;
      margin-left: 10px;
      margin-right: 10px; } }

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .header .logo {
    width: 178px; } }

@media screen and (max-width: 800px) {
  .header {
    text-align: center;
    background-color: #2d6d64;
    background-image: none; }
    .header > .wrap {
      min-width: calc(100% - 20px);
      box-sizing: border-box; }
    .header .logo {
      float: none;
      display: inline-block;
      width: 178px; }
    .header .top-slider .slide-img {
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0); }
    .header .top-slider .bullets {
      bottom: 75px;
      transform: translateX(-50%); }
      .header .top-slider .bullets li {
        margin: 0 10px;
        width: 10px;
        height: 10px; }
    .header-content {
      width: 100%;
      float: left;
      margin-left: 0%; }
      .header-content h1 {
        font-size: 50px; }
      .header-content .inner {
        top: auto;
        bottom: 12px;
        transform: translateY(0%);
        background-color: rgba(45, 109, 100, 0.75); }
        .header-content .inner .bottom-text {
          margin-top: 0; }
  .page .header-top {
    padding: 1.953125vh 0; }
  .page .header .logo {
    max-height: 50px;
    background: transparent url(../img/logo.png) center no-repeat;
    background-size: contain; }
  .menu-nav {
    float: none;
    right: auto;
    top: -3.90625vh;
    left: 0;
    transform: translateY(0);
    width: 360px;
    padding-top: 90px;
    padding-left: 20px;
    background-color: rgba(75, 75, 75, 0.85);
    z-index: 1;
    display: none; }
    .menu-nav ul {
      width: auto; }
    .menu-nav li.menu-item {
      width: 100%;
      min-width: 340px;
      margin-bottom: 50px; }
      .menu-nav li.menu-item a {
        font-size: 24px;
        color: #acacac; }
    .menu-nav .lang {
      float: none;
      position: relative;
      left: -10px;
      width: 294px;
      margin: 0 auto 90px;
      clear: left;
      border: 1px solid #cacaca;
      border-radius: 9px; }
      .menu-nav .lang:after {
        content: '';
        display: table;
        clear: both; }
      .menu-nav .lang .language-chooser {
        position: static; }
        .menu-nav .lang .language-chooser:before {
          display: none; }
        .menu-nav .lang .language-chooser li {
          float: left;
          width: 25%;
          margin-bottom: 0;
          font-size: 24px;
          line-height: 42px;
          text-transform: lowercase;
          display: block; }
          .menu-nav .lang .language-chooser li:not(.active) span {
            color: #acacac; }
          .menu-nav .lang .language-chooser li.active {
            background-color: #303030; }
            .menu-nav .lang .language-chooser li.active span {
              color: #fff; }
            .menu-nav .lang .language-chooser li.active:first-child {
              border-bottom-left-radius: 9px;
              border-top-left-radius: 9px; }
            .menu-nav .lang .language-chooser li.active:last-child {
              border-bottom-right-radius: 9px;
              border-top-right-radius: 9px; }
  .sandwich {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 38px;
    height: 24px;
    transform: translateY(-55%);
    z-index: 2; }
    .sandwich .inner {
      position: relative;
      margin-top: 12px;
      height: 2px;
      width: 100%;
      background-color: #fff;
      transition: background-color 0.3s; }
      .sandwich .inner:before, .sandwich .inner:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: #fff;
        transition: all .3s;
        transition-duration: 0.3s, 0.3s;
        transition-delay: 0.3s, 0s; }
      .sandwich .inner:before {
        bottom: 11px;
        transition-property: bottom, transform; }
      .sandwich .inner:after {
        top: 11px;
        transition-property: top, transform; }
      .sandwich .inner.is-active {
        background-color: transparent; }
        .sandwich .inner.is-active:before {
          bottom: 0;
          transform: rotate(45deg); }
        .sandwich .inner.is-active:after {
          top: 0;
          transform: rotate(-45deg); }
        .sandwich .inner.is-active:before, .sandwich .inner.is-active:after {
          transition-delay: 0s, 0.3s; } }

@media screen and (max-width: 800px) and (orientation: portrait) {
  .header > .wrap {
    height: calc(100% - 137px); }
  .header-content h1 {
    margin-top: 1vh; }
  .header-content .inner {
    padding-bottom: 40px; }
  .header-content .features li {
    font-size: 16px; }
  .header .top-slider .slide-box > li {
    height: calc(100vh - 137px); }
  .header .top-slider .bullets {
    bottom: 35px; } }

@media screen and (max-width: 800px) and (orientation: landscape) {
  .header > .wrap {
    height: calc(100% - 85px); }
  .header-top {
    padding: 10px; }
  .header-content h1 {
    margin-top: 1vh; }
  .header-content .inner {
    padding-bottom: 30px; }
    .header-content .inner .features li {
      font-size: 15px; }
    .header-content .inner .bottom-text p {
      padding-top: 0; }
  .header-content .features li {
    font-size: 16px; }
  .header .top-slider .slide-box > li {
    height: calc(100vh - 85px); }
  .header .top-slider .bullets {
    bottom: 25px; } }

@media screen and (max-width: 667px) and (orientation: landscape) {
  .header-top {
    padding: 7px 0; }
  .header > .wrap {
    height: calc(100% - 79px); }
  .header-content h1 {
    font-size: 38px; }
  .header-content .inner {
    padding-bottom: 30px; }
  .header-content .download {
    margin: 5px 0;
    padding: 12px 45px; }
  .header-content .features li {
    font-size: 14px; }
  .header-content .bottom-text p {
    font-size: 13px;
    line-height: 1; }
  .header .top-slider .slide-box > li {
    height: calc(100vh - 79px); }
    .header .top-slider .slide-box > li img {
      max-height: calc(100vh - 130px); }
  .header .top-slider .bullets {
    bottom: 25px;
    transform: translateX(-50%); } }

@media screen and (max-width: 640px) {
  .header-content p, .header-content li, .header-content h1 {
    text-align: left; }
  .header .top-slider .slide-box li img {
    max-width: calc(100vw - 20px); } }

@media screen and (max-width: 568px) and (orientation: landscape) {
  .header-content h1 {
    font-size: 30px; }
  .header-content .sub-title {
    margin-top: 2vh;
    font-size: 16px; }
  .header-content .download {
    padding: 8px 45px; } }

@media screen and (max-width: 480px) and (orientation: landscape) {
  .header-content .download {
    font-size: 18px; }
  .header-content .bottom-text {
    padding-top: 5px; }
    .header-content .bottom-text p {
      font-size: 12px; } }

@media screen and (max-height: 568px) {
  .header-content h1 {
    font-size: 34px; }
  .header-content .sub-title {
    font-size: 17px; }
  .menu-nav li.menu-item {
    margin-bottom: 6.6666666666666666666666666666667vh; } }

@media screen and (max-width: 380px) {
  .menu-nav {
    max-width: 100%; }
    .menu-nav .lang {
      margin-bottom: 60px; } }

@media screen and (max-width: 320px) and (orientation: portrait) {
  .header > .wrap {
    height: calc(100% - 79px); }
  .header .top-slider .slide-box > li {
    height: calc(100vh - 83px); }
  .header .slide-box {
    overflow: hidden; }
  .header-content .bottom-text p {
    font-size: 13px;
    line-height: 1; } }

@media screen and (max-width: 320px) and (orientation: portrait) {
  .header > .wrap {
    height: calc(100% - 79px); }
  .header .top-slider .slide-box > li {
    height: calc(100vh - 83px); } }

/*  iPhone4  */
@media screen and (max-width: 320px) and (max-height: 480px) {
  .header-content .inner h1 {
    font-size: 30px; }
  .header-content .download {
    margin: 3.2vh 0; }
  .header-content .features li {
    font-size: 14px; }
  .header-content .bottom-text p {
    padding-top: 0; } }

@media screen and (max-width: 320px) {
  .header-content .inner h1 {
    font-size: 38px; }
  .header-content .inner .download {
    padding: 15px 35px; } }

@media screen and (max-height: 480px) {
  .header-content .inner h1 {
    font-size: 30px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .header-content .inner {
    text-align: center; } }

/* === Main module === */
.platform {
  padding-top: 60px;
  padding-bottom: 70px; }
  .platform h2 {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 30px;
    line-height: 55px;
    text-align: center; }
    .platform h2 > span {
      font-family: "AllerRegular", sans-serif;
      font-weight: bold; }
  .platform .row {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    padding: 30px 0;
    border-radius: 9px;
    overflow: hidden; }
    .platform .row:after {
      content: " ";
      display: block;
      clear: both; }
    .platform .row-1 {
      background-color: #63b832; }
    .platform .row-2 {
      background-color: #0ba3ec; }
    .platform .row .elem {
      width: 50%;
      float: left; }
  .platform-win .device-img, .platform-mac .device-img {
    min-height: 177px;
    min-width: 330px;
    float: left;
    margin-top: -7px;
    margin-bottom: -5px;
    margin-left: -100px; }
  .platform-win .device-img {
    background: transparent url(../img/inst-win.png) center no-repeat; }
  .platform-mac .device-img {
    background: transparent url(../img/inst-mac.png) center no-repeat; }
  .platform-android .device-img, .platform-ios .device-img {
    min-height: 175px;
    min-width: 99px;
    float: left;
    margin-left: 25px;
    margin-right: 15px;
    margin-bottom: -15px; }
  .platform-android .device-img {
    background: transparent url(../img/inst-android.png) center no-repeat; }
  .platform-ios .device-img {
    background: transparent url(../img/inst-ios.png) center no-repeat; }
  .platform-title {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 21px;
    color: #fff; }
  .platform-android .dl-btn p {
    width: 100%;
    height: 100%;
    background: transparent url(../img/google-play.png) center no-repeat;
    background-size: 100%; }
  .platform-ios .dl-btn p {
    width: 100%;
    height: 100%;
    background: transparent url(../img/btn_dl-ios.png) center no-repeat;
    background-size: 100%; }
  .platform .qr-code {
    float: right;
    margin-top: 5px;
    margin-right: 30px; }
  .platform .bottom-text {
    margin-top: 45px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 14px;
    color: #fff; }

@media screen and (max-width: 800px) {
  .platform {
    padding-top: 0; }
    .platform-title {
      margin-top: 7.5vw;
      font-size: 7.5vw;
      text-align: center; }
    .platform h2 {
      margin: 15px 0;
      line-height: 1.1; }
    .platform .row {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      padding: 0;
      border-radius: 9px;
      overflow: hidden; }
      .platform .row:after {
        content: " ";
        display: block;
        clear: both; }
      .platform .row-1 {
        background-color: transparent; }
        .platform .row-1 .elem {
          background-color: #63b832; }
      .platform .row-2 {
        background-color: transparent; }
        .platform .row-2 .elem {
          background-color: #0ba3ec; }
      .platform .row .elem {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding: 35px 0;
        border-radius: 5px; }
      .platform .row .qr-code, .platform .row .bottom-text {
        display: none; }
    .platform .dl-btn-outer {
      display: block;
      clear: both;
      text-align: center; } }

@media screen and (max-width: 480px) {
  .platform-win .device-img, .platform-mac .device-img {
    min-height: 177px;
    min-width: 330px;
    float: left;
    margin-top: -7px;
    margin-bottom: -5px;
    margin-left: -129px; } }

@media screen and (max-width: 380px) {
  .platform-title {
    margin-top: 12.5vw;
    position: relative;
    right: 15px; } }

/* === Menu module === */
/* === Request module === */
/* === Team module === */
/* === Footer module === */
.footer {
  clear: both; }
  .footer .wrap {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #acacac; }
  .footer .col-1 {
    float: left; }
  .footer .col-2 {
    float: right;
    text-align: right; }
  .footer .social li {
    float: left;
    margin-right: 15px; }
    .footer .social li a {
      display: inline-block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      box-shadow: 0 0 10px 1px transparent;
      transition: box-shadow .2s; }
      .footer .social li a:hover {
        box-shadow: 0 0 10px 1px #000; }
    .footer .social li .fb {
      background: #1f599b url(../img/soc_fb.png) center no-repeat; }
    .footer .social li .tw {
      background: #00adf2 url(../img/soc_tw.png) center no-repeat; }
    .footer .social li .g-plus {
      background: #dc2d00 url(../img/soc_g-plus.png) center no-repeat; }
    .footer .social li .vk {
      background: #25668c url(../img/soc_vk.png) center no-repeat; }
  .footer .actual-links {
    font-size: 14px; }
    .footer .actual-links a {
      font-family: "helveticaneuecyr-roman", sans-serif;
      color: #757575; }
    .footer .actual-links .disc {
      position: relative;
      top: 3px;
      font-size: 1.5em;
      opacity: .4; }
  .footer .copy-author {
    padding-top: 10px; }
    .footer .copy-author p {
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 14px;
      color: #757575;
      opacity: .75; }
      .footer .copy-author p img {
        margin-bottom: -5px; }
      .footer .copy-author p a {
        color: #757575; }

@media screen and (max-width: 1000px) {
  .footer {
    padding: 0 10px; } }

@media screen and (max-width: 600px) {
  .footer .col {
    width: 100%; }
    .footer .col-2 {
      text-align: center; }
  .footer .social ul {
    text-align: justify; }
    .footer .social ul:after {
      content: '';
      display: inline-block;
      width: 100%; }
  .footer .social li {
    display: inline-block;
    float: none; }
    .footer .social li:last-of-type {
      margin-right: 0; } }

@media screen and (max-width: 340px) {
  .footer .copy-author p {
    font-size: 13px; } }

/* === Modal pay module === */
.modal-pay-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: scroll;
  z-index: 3;
  display: none; }

.modal-pay {
  position: absolute;
  top: 20vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  min-width: 480px;
  padding: 5vw 4vw;
  background-color: #fff;
  border: 1px solid #acacac;
  border-radius: 9px; }

/* === Header_Cabinet module === */
.cabinet .header-top {
  padding: 0;
  border-bottom: 1px solid #acacac; }

.cabinet .header .cabinet-sandwich {
  position: absolute;
  top: 0%;
  left: 0px;
  width: 66px;
  height: 100%;
  z-index: 2;
  border-left: 1px solid #acacac;
  border-right: 1px solid #acacac; }
  .cabinet .header .cabinet-sandwich .inner {
    position: absolute;
    top: 50%;
    left: 21px;
    margin-top: -1px;
    width: 24px;
    height: 2px;
    background-color: #acacac; }
    .cabinet .header .cabinet-sandwich .inner:before, .cabinet .header .cabinet-sandwich .inner:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #acacac; }
    .cabinet .header .cabinet-sandwich .inner:before {
      top: 7px; }
    .cabinet .header .cabinet-sandwich .inner:after {
      bottom: 7px; }

.cabinet .header .logo {
  height: 48px;
  margin: 7px 0 7px 80px;
  background-size: contain; }

.cabinet .header .mob-logout {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }
  .cabinet .header .mob-logout .logout {
    display: inline-block;
    padding-right: 10px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 16px;
    color: #4b4b4b; }
  @media screen and (min-width: 1000px) {
    .cabinet .header .mob-logout {
      display: none; } }
  @media screen and (max-width: 800px) {
    .cabinet .header .mob-logout .logout {
      color: #fff; } }
  @media screen and (max-width: 480px) {
    .cabinet .header .mob-logout .logout {
      padding-right: 5px; } }

.cabinet .header .to-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }
  .cabinet .header .to-right > span {
    display: none;
    margin-left: 2.5vw;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 30px; }

.cabinet .header .btn-add-funds {
  display: block;
  float: left;
  margin-right: 2.5vw;
  padding: 8px 34px;
  font-family: "helveticaneuecyr-roman", sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #ffa726;
  border: none;
  border-radius: 9px; }

.cabinet .header .ballance {
  float: right;
  margin-top: 5px; }
  .cabinet .header .ballance > span, .cabinet .header .ballance a {
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 16px;
    color: #4b4b4b; }

.cabinet .header .value {
  display: inline-block;
  margin-right: 3.5vw;
  font-family: "helveticaneuecyr-roman", sans-serif;
  font-size: 21px; }

.cabinet .header .logout {
  display: inline-block; }
  .cabinet .header .logout img {
    vertical-align: top; }

@media only screen and (max-width: 1000px) {
  .cabinet .header + .wrap {
    margin-top: 54px; }
  .cabinet .header .to-right {
    position: absolute;
    top: 65px;
    left: 0;
    padding: 10px 0;
    background-color: #fff;
    transform: translateY(0); }
    .cabinet .header .to-right > span {
      float: left;
      display: initial; }
  .cabinet .header .btn-add-funds {
    float: right; }
  .cabinet .header .ballance > span {
    display: none; }
  .cabinet .header .logout {
    display: none; } }

@media only screen and (max-width: 800px) {
  .cabinet .header .logo {
    margin-left: 0; }
  .cabinet .header .cabinet-sandwich .inner, .cabinet .header .cabinet-sandwich .inner:before, .cabinet .header .cabinet-sandwich .inner:after {
    background-color: #fff; }
  .cabinet .header .to-right > span {
    display: none; } }

@media only screen and (max-width: 600px) {
  .cabinet .header .to-right > span {
    display: none; } }

@media only screen and (max-width: 560px) {
  .cabinet .header .btn-add-funds {
    padding: 8px 16px; }
    .cabinet .header .btn-add-funds span {
      display: none; } }

/* === Page module === */
.page {
  min-height: 100vh;
  background-color: #efefef; }
  .page.cabinet {
    background-color: #fff; }
  .page .text {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    line-height: 1.2;
    color: #666; }
    .page .text p {
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 16px;
      line-height: 1.2;
      color: #666; }
      .page .text p a {
        color: #2d6d64; }

/* === Offer-page Main module === */
.offer {
  text-align: center; }
  .offer h2 {
    padding: 30px 0 5px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 36px;
    line-height: 55px; }
  .offer .sub-title {
    padding-bottom: .5rem;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px;
    line-height: 55px; }
  .offer .elem {
    float: left;
    width: 32%;
    margin-right: 1.99%;
    margin-bottom: 50px;
    padding-bottom: 40px;
    text-align: center;
    border-radius: 9px;
    overflow: hidden;
    background-color: #fff; }
    .offer .elem:nth-of-type(3n) {
      margin-right: 0; }
  .offer .country {
    padding: 17px 17px;
    text-align: left;
    background-color: #317768; }
    .offer .country:after {
      content: '';
      display: table;
      clear: both; }
    .offer .country-name {
      position: relative;
      top: 30px;
      transform: translateY(-50%);
      font-family: "helveticaneuecyr-light", sans-serif;
      font-size: 27px;
      line-height: 32px;
      color: #fff; }
    .offer .country img {
      float: left;
      margin-right: 10px; }
  .offer .price {
    margin: 40px auto 0;
    font-family: "AllerRegular", sans-serif;
    font-size: 60px; }
    .offer .price-old {
      position: relative;
      display: inline-block;
      margin: 16px auto;
      font-family: "Lato", sans-serif;
      font-size: 18px; }
  .offer .buy {
    display: inline-block;
    padding: 20px 46px;
    font-family: "helveticaneuecyr-medium", sans-serif;
    font-size: 21px;
    color: #fff;
    background-color: #ffa726;
    border: none;
    border-radius: 9px; }

.search-tariff {
  display: inline-block;
  max-width: 100%;
  padding: 4% 8%;
  background-color: #fff;
  border-radius: 9px;
  overflow: hidden; }
  .search-tariff input {
    box-sizing: border-box;
    width: 450px;
    max-width: calc(100% - 35px);
    padding: 10px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 18px;
    border: 1px solid #000;
    border-radius: 9px;
    outline: none;
    z-index: 3; }
  .search-tariff .btn-search {
    font-size: 20px;
    background: none;
    border: none; }
  .search-tariff hr {
    position: relative;
    left: -25%;
    width: 150%;
    margin: 7% 0; }
    @media screen and (max-width: 800px) {
      .search-tariff hr {
        left: auto;
        width: auto; } }

.drop {
  position: absolute;
  width: 450px;
  max-width: calc(100% - 35px);
  max-height: 177px;
  margin-top: 44px;
  border: 1px solid #000;
  border-radius: 9px;
  background-color: #fff;
  z-index: 2;
  overflow-y: auto;
  display: none; }
  @media screen and (max-width: 600px) {
    .drop {
      width: 100%;
      max-width: calc(100% - 8vw); } }
  .drop li {
    margin: 3px 5px;
    padding: 0 5px;
    text-align: left; }
    .drop li:hover {
      background-color: #2d6d64;
      cursor: pointer; }
      .drop li:hover * {
        color: #fff; }
  .drop .img-outer {
    display: inline-block;
    width: 25px; }
    .drop .img-outer img {
      width: 100%;
      vertical-align: bottom; }
  .drop .name {
    display: inline-block;
    margin-left: 7px;
    color: #4e4e4e; }
    .drop .name span {
      line-height: 25px;
      font-family: "helveticaneuecyr-light", sans-serif;
      font-size: 18px; }
  .drop .code {
    display: inline-block;
    margin-left: 7px;
    line-height: 25px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 18px;
    color: #a8a8a8; }

.result {
  position: relative;
  width: 100%; }
  .result .result-item {
    position: relative;
    margin-bottom: 30px; }
    .result .result-item:after {
      content: '';
      display: table;
      clear: both; }
    .result .result-item .result-name {
      font-size: 16px; }
      @media screen and (max-width: 480px) {
        .result .result-item .result-name {
          max-width: 100%; } }
  .result img {
    float: left;
    max-height: 61px;
    margin-right: 10px; }
  .result-name, .result-price {
    font-family: "Lato", sans-serif;
    font-size: 24px;
    text-align: left; }
  .result-price {
    margin-top: 19px;
    font-size: 18px; }
  .result-buy {
    position: absolute;
    right: 0;
    bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffa726; }

@media screen and (max-width: 800px) {
  .offer .sub-title {
    padding-bottom: 1.5rem;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px;
    line-height: 24px; }
  .offer .search-tariff {
    position: relative;
    padding: 4% 4%;
    overflow: visible; }
    .offer .search-tariff input {
      width: 450px;
      max-width: 100%;
      padding-right: 40px;
      font-size: 16px; }
    .offer .search-tariff .btn-search {
      position: absolute;
      top: 4.9vw;
      right: 6%;
      font-size: 20px;
      color: #7f7f7f; }
  .offer .elem {
    width: 100%;
    margin-right: 0; }
  .offer .price {
    margin: 40px auto 0;
    font-family: "AllerRegular", sans-serif;
    font-size: 60px; } }

@media screen and (width: 640px) {
  .offer .search-tariff .btn-search {
    top: 3.9vw; }
    .is-wide .offer .search-tariff .btn-search {
      top: 4.9vw;
      right: 10%; } }

@media screen and (max-width: 600px) {
  .packages .offer .search-tariff input {
    width: 100%;
    max-width: 100%; } }

@media screen and (max-width: 480px) {
  .offer .search-tariff .btn-search {
    top: 5.6vw; }
  .offer .elem {
    position: relative;
    width: 100%;
    margin-right: 0;
    text-align: left; }
  .offer .price {
    float: left;
    width: 100%;
    margin-left: 10px; }
    .offer .price-old {
      float: left;
      margin-left: 15px; }
  .offer .buy {
    position: absolute;
    right: 10px;
    top: calc(50% + 47px);
    transform: translateY(-50%);
    padding: 20px 8.125vw;
    font-family: "helveticaneuecyr-medium", sans-serif;
    font-size: 21px;
    color: #fff;
    background-color: #ffa726;
    border: none;
    border-radius: 9px; }
  .offer .result-buy {
    position: static;
    transform: none;
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px; } }

@media screen and (max-width: 320px) {
  .offer .search-tariff input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px; }
  .offer .search-tariff input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px; }
  .offer .search-tariff input:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px; }
  .offer .search-tariff input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px; } }

/* === Downloads-page Main module === */
.downloads h2 {
  text-align: left; }
  .downloads h2:last-of-type {
    margin-top: 8%; }

.downloads .elem {
  margin-top: 4.25%; }
  .downloads .elem:after {
    content: '';
    display: table;
    clear: both; }
  .downloads .elem.detected .dl-btn {
    background-image: none;
    background-color: #ffa726; }
  .downloads .elem .title {
    margin-bottom: 25px;
    font-family: "helveticaneuecyr-medium", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #2d6d64; }
    .downloads .elem .title .fa {
      display: none; }

.downloads .device-img.desk {
  float: right;
  width: 41%;
  text-align: right; }

.downloads .device-img.mob {
  float: left;
  width: 10%;
  margin-right: 8.3%; }

.downloads .device-img img {
  max-width: 100%; }

.downloads .qr-code {
  width: 170px;
  height: 170px;
  margin-top: 10px;
  margin-right: 0;
  margin-left: 5%; }

@media screen and (max-width: 800px) {
  .downloads h2 {
    text-align: center; }
  .downloads .elem {
    position: relative;
    margin-top: 7%;
    padding-bottom: 7%;
    overflow: hidden; }
    .downloads .elem.detected {
      padding: 7%;
      border-radius: 9px;
      background-color: #63b832;
      margin-bottom: 62.5vw;
      overflow: visible; }
      .downloads .elem.detected .title {
        margin-top: 40px;
        color: #fff; }
      .downloads .elem.detected .device-img.desk {
        margin-left: -130px;
        float: left; }
      .downloads .elem.detected .text {
        position: absolute;
        top: 100%;
        color: #000; }
    .downloads .elem .title {
      margin-top: 20px;
      text-transform: capitalize;
      text-align: center;
      color: #000; }
      .downloads .elem .title span {
        display: none; }
      .downloads .elem .title .fa {
        display: initial; }
    .downloads .elem:nth-of-type(2):before, .downloads .elem:nth-of-type(3):before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 150px;
      height: 1px;
      background-color: #acacac; }
  .downloads .device-img.desk {
    float: none;
    width: 100%;
    text-align: center; }
  .downloads .device-img.mob {
    float: none;
    width: 100%;
    margin-right: 0;
    text-align: center; }
  .downloads .device-img img {
    max-width: 100%; }
  .downloads .qr-code {
    display: none; }
  .dl-btn {
    width: 195px;
    height: 67px;
    font-size: 21px;
    line-height: 67px; } }

/* === About-page Main module === */
.about {
  padding-top: 35px;
  padding-bottom: 70px; }
  .about .col-1 {
    width: 58.33333%;
    float: left; }
    .about .col-1 img {
      max-width: 100%; }
  .about .col-2 {
    width: 41.66667%;
    float: left;
    padding-left: 20px; }
  .about .up-title {
    margin-bottom: 12px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #0ba3ec;
    text-transform: uppercase; }
  .about h1 {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: "AllerRegular", sans-serif;
    font-size: 45px;
    text-transform: uppercase; }
    .about h1:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: #b3b3b3; }
  .about .text p {
    margin-bottom: 1rem; }
  .about .text ul {
    margin-bottom: 1rem;
    padding-left: 20px;
    list-style-type: disc; }
    .about .text ul li {
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 16px; }
  .about .text a {
    color: #0ba3ec; }

@media screen and (max-width: 800px) {
  .about {
    padding-top: 35px;
    padding-bottom: 70px; }
    .about .col-1 {
      width: 100%;
      float: left; }
      .about .col-1 img {
        max-width: 100%; }
    .about .col-2 {
      width: 100%;
      float: left;
      margin-top: -150px;
      padding-left: 0px;
      background-color: rgba(239, 239, 239, 0.75); }
    .about .up-title {
      margin-top: 30px;
      text-align: center; }
    .about h1 {
      text-align: center;
      text-transform: none; }
      .about h1:after {
        left: 50%;
        margin-left: -50px; }
    .about .text {
      font-size: 21px; } }

/* === FAQ-page Main module === */
.faq {
  padding-top: 48px;
  padding-bottom: 70px; }
  .faq h1 {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px; }
  .faq .not-find {
    width: 66.66667%;
    float: left;
    padding-bottom: 30px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 18px; }
    .faq .not-find p {
      display: inline-block;
      margin-right: 20px; }
    .faq .not-find .btn-help {
      padding: 16px 62px;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 18px;
      color: #fff;
      background-color: #2d6d64;
      border: none;
      border-radius: 9px; }
  .faq .search-faq {
    width: 33.33333%;
    float: left;
    margin-top: 5px;
    border-radius: 9px;
    overflow: hidden; }
    .faq .search-faq .input-outer {
      box-sizing: border-box;
      display: inline-block;
      width: 450px;
      max-width: calc(100% - 35px);
      padding: 10px;
      border: 1px solid #000;
      border-radius: 9px;
      outline: none;
      background-color: #fff; }
    .faq .search-faq input {
      width: 100%;
      font-family: "helveticaneuecyr-light", sans-serif;
      font-size: 18px;
      border: none; }
    .faq .search-faq .btn-search {
      font-size: 20px;
      background: none;
      border: none; }
      .faq .search-faq .btn-search:focus {
        outline: none; }
  .faq .search-results {
    clear: both; }
  .faq .sr-item {
    padding: 15px 0;
    position: relative; }
    .faq .sr-item:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: #acacac; }
    .faq .sr-item:last-of-type:after {
      display: none; }
    .faq .sr-item a {
      color: #000; }
  .faq .sr-platform {
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #2d6d64; }
  .faq .sr-title {
    padding-bottom: 5px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 18px; }
  .faq .sr-descr {
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 15px; }
  .faq .content-outer {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border: 1px solid #acacac;
    border-radius: 9px;
    clear: both; }
    .faq .content-outer:after {
      content: " ";
      display: block;
      clear: both; }
  .faq .col-tabs {
    width: 25%;
    float: left; }
    .faq .col-tabs ul {
      margin-top: 15px; }
    .faq .col-tabs li {
      padding: 10px 15px;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 18px;
      line-height: 1.2;
      cursor: default; }
      .faq .col-tabs li.active-tab, .faq .col-tabs li:hover {
        color: #fff;
        background-color: #2d6d64; }
  .faq .col-answers {
    width: 75%;
    float: left;
    padding: 15px 28px;
    border-left: 1px solid #acacac; }
    .faq .col-answers div[class^="tab-content"]:not(:first-of-type) {
      display: none; }
  .faq .question {
    padding: 15px 0px 13px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 21px; }
  .faq .answer {
    padding-bottom: 15px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 16px;
    line-height: 1.2;
    border-bottom: 1px solid #efefef; }
    .faq .answer:last-of-type {
      border-bottom: none; }
    .faq .answer ul {
      list-style: disc;
      padding-left: 18px; }
    .faq .answer li {
      margin-bottom: 15px; }
      .faq .answer li:last-child {
        margin-bottom: 0; }
  .faq .mobile {
    display: none; }

.faq-popup {
  position: absolute;
  top: 20vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  min-width: 480px;
  padding: 5vw 4vw;
  background-color: #fff;
  border: 1px solid #acacac;
  border-radius: 9px;
  transition: top .6s, bottom .6s; }
  .faq-popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: scroll;
    z-index: 3;
    display: none; }
  .faq-popup .text {
    margin-bottom: 10px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    line-height: 1;
    color: #000; }
    .faq-popup .text.margin {
      margin-bottom: 35px; }
    .faq-popup .text a {
      color: #0ba3ec;
      text-decoration: underline; }
  .faq-popup .os {
    margin-bottom: 20px;
    border: 1px solid #acacac;
    border-radius: 9px;
    overflow: hidden; }
    .faq-popup .os:after {
      content: '';
      display: table;
      clear: both; }
    .faq-popup .os input {
      display: none; }
    .faq-popup .os label {
      float: left;
      box-sizing: border-box;
      width: 25%;
      padding: 10px 0;
      font-family: "helveticaneuecyr-roman", sans-serif;
      text-align: center;
      border-right: 1px solid #acacac; }
      .faq-popup .os label .fa {
        width: 100%;
        margin-bottom: 5px; }
      .faq-popup .os label:last-of-type {
        border-right: none; }
    .faq-popup .os input:checked + label {
      background-color: #2d6d64;
      color: #fff; }
  .faq-popup .radio {
    margin-bottom: 20px; }
    .faq-popup .radio input {
      display: none; }
    .faq-popup .radio label {
      display: inline-block;
      width: 100%;
      margin-bottom: 5px;
      font-family: "helveticaneuecyr-light", sans-serif;
      color: #aaafae; }
    .faq-popup .radio .circle {
      display: inline-block;
      margin-bottom: -3px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #acacac;
      padding: 2px; }
      .faq-popup .radio .circle:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: tr; }
    .faq-popup .radio input:checked + label {
      color: #000; }
      .faq-popup .radio input:checked + label .circle:before {
        background-color: #2d6d64; }
  .faq-popup .field {
    float: left;
    width: 100%;
    margin-bottom: 15px; }
    .faq-popup .field input, .faq-popup .field textarea {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      border: 1px solid #acacac;
      border-radius: 9px; }
      .faq-popup .field input:focus, .faq-popup .field textarea:focus {
        border: 1px solid #2d6d64;
        outline: none; }
    .faq-popup .field textarea {
      height: 74px; }
    .faq-popup .field label {
      display: inline-block;
      width: 100%;
      margin-bottom: 7px;
      font-family: "helveticaneuecyr-roman", sans-serif; }
    .faq-popup .field.half-width {
      width: 45%; }
      .faq-popup .field.half-width.to-right {
        float: right; }
    .faq-popup .field .error {
      border: 1px solid #ea4848; }
    .faq-popup .field .inmail-error {
      display: inline-block;
      width: 100%;
      font-family: "helveticaneuecyr-light", sans-serif;
      font-size: 12px;
      color: #ea4848;
      text-align: right;
      display: none; }
    .faq-popup .field .error + .inmail-error {
      height: 0;
      display: block; }
  .faq-popup .file {
    clear: both;
    font-family: "helveticaneuecyr-light", sans-serif; }
    .faq-popup .file input[type="file"] {
      display: none; }
    .faq-popup .file label p {
      margin-top: 5px; }
      .faq-popup .file label p span {
        font-weight: bold;
        color: #2d6d64; }
  .faq-popup .btn-send {
    display: block;
    margin: 30px auto 0;
    padding: 8px 85px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #ffa726;
    border: none;
    border-radius: 9px;
    display: none; }
  .faq-popup .hidden, .faq-popup .questions {
    display: none; }

.modal-help {
  position: relative;
  margin: 30vw auto;
  width: 450px;
  max-width: 90%;
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px #999;
  border-radius: 9px; }
  @media screen and (max-width: 380px) {
    .modal-help {
      padding: 50px 25px; } }
  .modal-help-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.35); }
  .modal-help .title {
    margin: 5px 0 15px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 30px;
    line-height: 1;
    text-align: center;
    color: #2d6d64; }
  .modal-help .text {
    margin-bottom: 5px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 21px;
    text-align: center; }
  .modal-help .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #acacac; }

@media screen and (max-width: 800px) {
  .faq-popup {
    padding: 5vw; } }

@media screen and (max-width: 800px) {
  .faq {
    padding-top: 18px;
    padding-bottom: 18px; }
    .faq h1 {
      text-align: center;
      margin-bottom: 10px; }
    .faq .not-find {
      position: fixed;
      bottom: 0%;
      left: 0;
      width: 100%;
      padding-top: 30px;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 1;
      display: none; }
      .faq .not-find p {
        margin-right: 0;
        margin-bottom: 10px;
        font-size: 24px; }
        .faq .not-find p span {
          display: none; }
    .faq .search-faq {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 9vw; }
      .faq .search-faq .input-outer {
        max-width: 100%;
        width: 100%;
        border: none; }
        .faq .search-faq .input-outer input {
          box-sizing: border-box;
          padding: 10px;
          border: 1px solid #000;
          border-radius: 9px; }
      .faq .search-faq .btn-search {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
  .content-outer {
    display: none; }
  .faq .mobile {
    display: block; }
  .mobile .tab-outer {
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #acacac;
    border-radius: 9px;
    overflow: hidden; }
  .mobile .tab {
    position: relative;
    padding: 20px 16px;
    padding-right: 44px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 28px;
    background-color: #2d6d64;
    color: #fff; }
    .mobile .tab .fa {
      position: absolute;
      top: 50%;
      margin-top: -14px;
      right: 20px;
      transition: transform .4s; }
      .mobile .tab .fa.rotated {
        transform: rotate(-180deg); }
  .mobile .tab-content {
    display: none; }
  .mobile .question {
    padding-left: 16px;
    padding-right: 16px; }
  .mobile .answer {
    padding-left: 32px;
    padding-right: 16px; } }

@media screen and (max-width: 480px) {
  .faq-popup {
    top: 10vw;
    min-width: 320px; }
    .faq-popup .os label {
      float: none;
      display: block;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #acacac; }
      .faq-popup .os label:last-of-type {
        border-bottom: none; }
      .faq-popup .os label .fa {
        width: auto; }
    .faq-popup .field.half-width {
      width: 100%; } }

/* === FAQ-page Main module === */
.faq-h .bg {
  display: none;
  position: relative;
  width: 100%;
  height: 55vw;
  max-height: 100vh;
  margin-top: -134px;
  padding-top: 134px;
  background: transparent url("../img/faq-hallway.jpg") center no-repeat;
  background-size: cover; }
  @media screen and (max-width: 800px) {
    .faq-h .bg {
      margin-top: -90px;
      padding-top: 90px; } }
  @media screen and (max-width: 320px) {
    .faq-h .bg {
      height: 72vw;
      background-size: 370px; } }

.faq-h h1 {
  padding-top: 80px;
  font-family: "helveticaneuecyr-light", sans-serif;
  font-size: 60px;
  line-height: 55px; }
  .faq-h h1 span {
    color: #2d6d64; }
  @media screen and (max-width: 1000px) {
    .faq-h h1 {
      padding-top: 8vw; } }
  @media screen and (max-width: 640px) {
    .faq-h h1 {
      font-size: 50px; } }
  @media screen and (max-width: 480px) {
    .faq-h h1 {
      font-size: 40px;
      line-height: 1.2; } }
  @media screen and (max-width: 414px) {
    .faq-h h1 {
      padding-top: 10px;
      font-size: 36px; } }
  @media screen and (max-width: 320px) {
    .faq-h h1 {
      padding-top: 10px;
      font-size: 36px; } }

.faq-h h2 {
  margin: 40px 0;
  font-family: "helveticaneuecyr-light", sans-serif;
  font-size: 36px;
  line-height: 55px;
  text-align: center; }

.faq-h .row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70px; }
  .faq-h .row:after {
    content: " ";
    display: block;
    clear: both; }

.faq-h .col {
  width: 50%;
  float: left;
  text-align: center; }
  .faq-h .col img {
    max-width: 100%; }

.faq-h .caption {
  margin-top: 18px;
  font-family: "helveticaneuecyr-roman", sans-serif;
  font-size: 21px;
  color: #000; }

@media screen and (max-width: 800px) {
  .faq-h .wrap > h2 {
    font-size: 36px; } }

.title-double {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 3vw; }
  @media screen and (max-width: 800px) {
    .title-double {
      display: none; } }

.down-arrow {
  margin-top: -30px;
  text-align: center; }
  .down-arrow .fa {
    font-size: 46px;
    color: #2d6d64; }

/* === About-page Main module === */
.policy {
  padding-top: 35px;
  padding-bottom: 70px; }
  .policy ul {
    padding-bottom: 20px; }
  .policy li a {
    display: inline-block;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 18px;
    line-height: 34px;
    color: #0ba3ec;
    text-decoration: underline; }
  .policy h2 {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-family: "helveticaneuecyr-medium", sans-serif;
    font-size: 24px;
    text-transform: uppercase; }
    .policy h2:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: #b3b3b3; }
  .policy .text p {
    margin-bottom: 1rem; }

@media screen and (max-width: 800px) {
  .policy li a {
    margin-top: 15px;
    text-align: center; }
  .policy h2 {
    text-align: center;
    text-transform: none; }
    .policy h2:after {
      left: 50%;
      margin-left: -50px; }
  .policy .text {
    font-size: 21px;
    text-align: justify; } }

/* === 404-page Main module === */
/* === Search Main module === */
/* === Login-page Main module === */
.page-login {
  background-color: #fff; }

.login-bgc {
  background-color: #fff; }

.login-block {
  width: 85%;
  margin: 0 auto 7%;
  padding: 4.6%;
  background-color: #efefef;
  text-align: center;
  border-radius: 9px; }

.login .outer {
  max-width: 240px;
  margin: 0 auto; }
  .login .outer a {
    display: inline-block;
    margin-bottom: 15px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #000; }

.login h1 {
  margin-bottom: 20px;
  font-family: "helveticaneuecyr-roman", sans-serif;
  font-size: 24px;
  line-height: 55px; }

.login label {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-family: "helveticaneuecyr-light", sans-serif;
  font-size: 16px;
  text-align: left; }

.login input {
  width: 100%;
  margin-bottom: 20px;
  padding: 8px 12px;
  border: 1px solid #000;
  background-color: transparent;
  border-radius: 9px; }
  .login input:focus {
    border: 1px solid #2d6d64;
    outline: none; }

.login button {
  min-width: 240px;
  width: 24%;
  padding: 8px 12px;
  font-family: "helveticaneuecyr-roman", sans-serif;
  font-size: 16px;
  color: #fff;
  border-radius: 9px;
  border: none;
  background-color: #2d6d64; }

.login-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  padding: 40px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 9px; }
  .login-popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: none; }
  .login-popup p {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 22px;
    line-height: 1.2; }
  .login-popup button {
    margin-top: 25px;
    padding: 10px 20px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #fff;
    border-radius: 9px;
    border: none;
    background-color: #ffa726; }

/* === Cabinet-page Main module === */
.cabinet .wrap {
  padding-left: 0;
  padding-right: 0; }

.cabinet > .wrap {
  position: relative;
  overflow: hidden; }

.cabinet-menu {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 240px;
  height: 100%;
  min-height: calc(100vh - 62px);
  background-color: #4b4b4b; }
  .cabinet-menu li {
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid #acacac; }
    .cabinet-menu li.selected {
      background-color: #303030; }
      .cabinet-menu li.selected .icon-1 {
        background: transparent url(../img/cabinet/avatar_active.png) 0 0 no-repeat;
        background-size: 100%;
        width: 55px;
        height: 55px; }
        .cabinet-menu li.selected .icon-1 + span {
          margin-bottom: 0;
          line-height: 55px;
          transition: margin-bottom .35s; }
      .cabinet-menu li.selected .icon-2 {
        background-position: -5px -28px; }
      .cabinet-menu li.selected .icon-3 {
        background-position: -33px -57px; }
      .cabinet-menu li.selected .icon-4 {
        background-position: -5px -84px; }
      .cabinet-menu li.selected .icon-5 {
        background-position: -61px -28px; }
      .cabinet-menu li.selected span {
        color: #fff;
        transition: width .5s; }
    .cabinet-menu li a {
      display: inline-block;
      padding: 20px 0 20px 20px; }
  .cabinet-menu span {
    display: inline-block;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #acacac;
    white-space: nowrap;
    vertical-align: bottom; }
  .cabinet-menu .icon {
    display: inline-block;
    height: 23px;
    width: 23px;
    background: url("../img/cabinet/cabinet-menu-sprite.png") no-repeat;
    vertical-align: bottom; }
    .cabinet-menu .icon-1 {
      background-position: -5px -0px; }
    .cabinet-menu .icon-2 {
      background-position: -61px -0px; }
    .cabinet-menu .icon-3 {
      background-position: -5px -56px; }
    .cabinet-menu .icon-4 {
      background-position: -61px -57px; }
    .cabinet-menu .icon-5 {
      height: 24px;
      background-position: -33px -28px; }
  .cabinet-menu.is-collapsed {
    width: 66px; }
    .cabinet-menu.is-collapsed li.selected .icon-1 {
      width: 23px;
      height: 23px;
      background: url("../img/cabinet/cabinet-menu-sprite.png") no-repeat;
      background-position: -33px -0px; }
      .cabinet-menu.is-collapsed li.selected .icon-1 + span {
        margin-bottom: -28px; }
    .cabinet-menu.is-collapsed li span {
      overflow: hidden;
      width: 0; }

.cabinet .main {
  float: right;
  width: calc(100% - 240px);
  min-height: calc(100vh - 153px); }
  .cabinet .main.is-wide {
    width: calc(100% - 66px); }

.cabinet .login-block {
  margin-top: 5%; }
  .cabinet .login-block h1 {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px;
    line-height: 55px; }
  .cabinet .login-block .outer > * {
    display: block;
    width: 224px;
    margin: 0 auto 3px; }
  .cabinet .login-block label {
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 16px;
    text-align: left; }
  .cabinet .login-block input {
    width: 100% !important;
    box-sizing: border-box;
    padding: 7px;
    border: 1px solid #2d6d64;
    border-radius: 9px; }
  .cabinet .login-block button {
    padding: 9px 0;
    color: #fff;
    background-color: #2d6d64;
    border-radius: 9px;
    border: none; }
    .cabinet .login-block button#goLogin {
      margin-top: 18px; }

@media only screen and (max-width: 600px) {
  .cabinet-menu {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 0px;
    overflow: hidden;
    display: none; }
    .cabinet-menu.is-collapsed {
      width: 100%; }
      .cabinet-menu.is-collapsed li span {
        width: auto; }
      .cabinet-menu.is-collapsed li.selected .icon-1 + span {
        margin-bottom: 0; }
    .cabinet-menu li a {
      display: inline-block;
      width: 100%; }
  .cabinet .main {
    width: 100%; }
    .cabinet .main.is-wide {
      width: 100%; } }

/* === Packages Cabinet module === */
.packages section {
  padding-left: 3.96%;
  text-align: center;
  position: relative; }
  .packages section h2 {
    padding: 5.5% 0 2.4%;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px;
    text-align: left; }
  .packages section h3 {
    padding: 4.18% 1.14%;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 18px;
    text-align: left; }

.packages .search-tariff {
  width: 100%;
  background-color: #efefef;
  text-align: center; }

.packages .drop-outer {
  display: inline-block;
  width: 450px;
  margin-left: -35px; }
  .packages .drop-outer .drop {
    max-width: 100%;
    margin-top: 48px;
    left: 50%;
    transform: translateX(-50%); }

.packages .drop {
  left: 50%;
  transform: translateX(-50%); }

.packages .result {
  max-width: 485px;
  margin: 0 auto; }
  .packages .result .result-item {
    position: relative; }
    .packages .result .result-item:after {
      content: '';
      display: table;
      clear: both; }

.packages .buy {
  padding: 7px 40px;
  font-family: "helveticaneuecyr-medium", sans-serif;
  font-size: 21px;
  color: #fff;
  background-color: #ffa726;
  border: none;
  border-radius: 9px; }

.packages .country {
  background-color: #fff;
  border-bottom: 1px solid #efefef; }
  .packages .country-name {
    top: 20px;
    font-family: "helveticaneuecyr-light", sans-serif;
    font-size: 21px;
    line-height: 19px;
    color: #000; }
  .packages .country img {
    height: 38px; }

.packages .row {
  padding-bottom: 7%; }
  .packages .row:after {
    content: '';
    display: table;
    clear: both; }

.packages .elem {
  margin-bottom: 1.99%;
  box-shadow: 0px 0px 17px -2px #cfcfcf; }
  .packages .elem .buy {
    padding: 10px 62px;
    transition: background-color .2s; }
    .packages .elem .buy:hover {
      background-color: #2d6d64; }

.packages .price {
  font-size: 50px; }
  .packages .price-old {
    font-size: 16px; }

@media only screen and (max-width: 600px) {
  .packages section {
    padding-left: 10px;
    padding-right: 10px; }
    .packages section h3 {
      text-align: center; } }

/* === Profile Cabinet module === */
.profile section {
  padding-left: 3.96%; }
  .profile section h2 {
    padding: 5.5% 0 2.4%;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px;
    text-align: left; }

.profile .content {
  margin-bottom: 12.5%;
  padding: 6.4% 0;
  background-color: #efefef;
  border-radius: 9px; }
  .profile .content .inner {
    position: relative; }
    .profile .content .inner:after {
      content: '';
      display: table;
      clear: both; }

.ava {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  float: left;
  width: 36.9%;
  text-align: center; }
  .ava input[type="file"] {
    display: none; }
  .ava .img-outer {
    display: inline-block;
    max-width: 187px;
    max-height: 187px;
    border-radius: 50%;
    overflow: hidden; }
    .ava .img-outer label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: none; }
  .ava img {
    display: block;
    max-width: 100%; }
  .ava.edit-mode .img-outer {
    position: relative;
    display: inline-block; }
    .ava.edit-mode .img-outer:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.4) url(../img/cabinet/avatar_active_edit.png) center no-repeat;
      background-size: 50px;
      border-radius: 50%; }

.info {
  float: right;
  width: 63%;
  padding-left: 8%;
  border-left: 1px solid #acacac; }
  .info .line {
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 18px;
    line-height: 40px; }
    .info .line-name {
      float: left;
      width: 42%;
      color: #2d6d64; }
    .info .line-value {
      float: right;
      width: 55%;
      color: #000;
      white-space: nowrap;
      text-rendering: auto;
      text-overflow: ellipsis;
      overflow: hidden; }
    .info .line input {
      width: 100%;
      padding: 0;
      font-size: 19px;
      box-sizing: border-box;
      border: none;
      outline: none;
      background-color: transparent;
      display: none; }
    .info .line label {
      width: auto;
      display: inline-block;
      margin-right: 15px;
      line-height: 1;
      display: none; }
      .info .line label span {
        font-size: 16px;
        line-height: 1; }
    .info .line.pass .line-name {
      display: none; }
    .info .line.edit-mode.pass .line-name {
      display: block; }
    .info .line.error {
      position: relative; }
      .info .line.error.attention {
        -webkit-animation: inputFlash .5s ease-in forwards;
        animation: inputFlash .5s ease-in forwards; }
      .info .line.error input[type="text"], .info .line.error input[type="email"] {
        border: 1px solid #ea4848 !important; }
      .info .line.error .error-msg {
        display: block;
        height: 14px; }
    .info .line .error-msg {
      position: absolute;
      bottom: 6px;
      right: 0;
      padding-right: 30px;
      font-family: "helveticaneuecyr-light", sans-serif;
      font-size: 12px;
      color: #ea4848;
      text-align: right;
      display: none; }

@-webkit-keyframes inputFlash {
  0% {
    box-shadow: 0px 0px 0px -2px coral; }
  33.3% {
    box-shadow: 0px 0px 12px -2px coral; }
  66.6% {
    box-shadow: 0px 0px 0px -2px coral; }
  100% {
    box-shadow: 0px 0px 12px -2px coral; } }

@keyframes inputFlash {
  0% {
    box-shadow: 0px 0px 0px -2px coral; }
  33.3% {
    box-shadow: 0px 0px 12px -2px coral; }
  66.6% {
    box-shadow: 0px 0px 0px -2px coral; }
  100% {
    box-shadow: 0px 0px 12px -2px coral; } }
  .info .btns {
    text-align: left;
    clear: both; }
    .info .btns .btn {
      cursor: default; }
  .info .btn-edit {
    display: inline-block;
    margin-top: 13px;
    padding: 9px 80px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #2d6d64;
    border-radius: 9px; }
  .info .btn-save {
    display: inline-block;
    margin-top: 13px;
    padding: 9px 80px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #2d6d64;
    border: none;
    border-radius: 9px;
    display: none; }
  .info .btn-cancel {
    display: inline-block;
    margin-top: 13px;
    margin-right: 30px;
    padding: 9px 10px 9px 0;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 16px;
    color: #dc2d00;
    display: none; }

@media only screen and (min-width: 1000px) {
  .info .line.edit-mode {
    width: 50%;
    float: left; }
    .info .line.edit-mode .line-name {
      width: 100%;
      font-size: 16px;
      color: #000; }
    .info .line.edit-mode .line-value.not-edit {
      width: 100%;
      font-size: 16px;
      line-height: 1;
      text-align: left; }
    .info .line.edit-mode.pass .line-name {
      display: block; }
  .info .line input[type="text"], .info .line input[type="email"], .info .line input[type="password"] {
    width: calc(100% - 30px);
    margin-bottom: 10px;
    padding: 5px 7px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 9px;
    font-size: 16px; }
    .info .line input[type="text"]:focus, .info .line input[type="email"]:focus, .info .line input[type="password"]:focus {
      border: 2px solid #2d6d64; }
  .ava.edit-mode label {
    display: block; } }

@media only screen and (max-width: 1000px) {
  .profile section {
    padding: 0 10px; }
    .profile section h2 {
      font-size: 26px;
      text-align: center;
      color: #2d6d64; }
  .profile .content {
    background-color: transparent; }
  .ava {
    position: static;
    width: 100%;
    transform: translateY(0); }
  .info {
    width: 100%;
    margin-top: 15px;
    padding-left: 0;
    border-left: none; }
    .info .line {
      margin-bottom: 15px;
      padding: 14px;
      line-height: 1;
      border: 1px solid #acacac;
      border-radius: 9px; }
      .info .line.pass {
        display: none; }
      .info .line.edit-mode.pass {
        display: block; }
      .info .line:after {
        content: '';
        display: table;
        clear: both; }
      .info .line-name {
        font-size: 16px;
        color: #4b4b4b; }
      .info .line-value {
        font-size: 19px;
        text-align: right; }
      .info .line.error {
        border-color: coral;
        box-shadow: 0px 0px 12px -2px coral; }
        .info .line.error.attention {
          -webkit-animation: inputFlash .5s ease-in forwards;
          animation: inputFlash .5s ease-in forwards; }
    .info .btns {
      text-align: center; }
    .info .btn-save, .info .btn-edit {
      padding: 15px 40px;
      font-size: 20px; }
    .info .btn-cancel {
      margin-right: 15px;
      padding: 9px 10px;
      font-size: 20px; } }

/* === Payments Cabinet module === */
.payments section {
  padding-left: 3.96%; }
  .payments section h2 {
    padding: 5.5% 0 2.4%;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 24px;
    text-align: left; }

.payments .pagination {
  margin-top: 40px;
  margin-bottom: 70px;
  text-align: center; }
  .payments .pagination ul {
    margin: 0 auto;
    display: inline-block; }
  .payments .pagination li {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 10px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 14px;
    color: #4b4b4b;
    text-align: center;
    line-height: 18px;
    border-radius: 9px; }
    .payments .pagination li a {
      color: #4b4b4b; }
  .payments .pagination .current {
    background-color: #2d6d64; }
    .payments .pagination .current a {
      color: #fff; }

.thead {
  background-color: #2d6d64;
  font-family: "helveticaneuecyr-roman", sans-serif;
  font-size: 0;
  color: #fff; }

.tr {
  margin-bottom: 6px;
  font-size: 16px;
  text-align: justify;
  font-size: 0; }
  .tr:after {
    content: '';
    display: inline-block;
    width: 100%; }
  .tr .td {
    display: inline-block;
    padding: 15px 0;
    font-size: 16px;
    line-height: 26px; }
    .tr .td:first-of-type {
      padding-left: 20px; }
    .tr .td:last-of-type {
      padding-right: 20px; }

.tbody {
  width: 100%; }
  .tbody .month {
    display: block;
    width: 100%;
    padding: 15px 20px;
    font-family: "helveticaneuecyr-light", sans-serif;
    color: #acacac; }
  .tbody .tr {
    box-shadow: 0px 1px 15px 0px #efefef; }
    .tbody .tr:hover {
      background-color: #efefef; }
  .tbody .td {
    font-family: "helveticaneuecyr-medium", sans-serif;
    text-align: center;
    color: #4b4b4b; }
    .tbody .td:first-of-type {
      text-align: left; }
    .tbody .td:last-of-type {
      text-align: right; }

.table .details {
  min-width: 30%; }

.table .paytype {
  box-sizing: content-box;
  min-width: 93px; }
  .table .paytype img {
    vertical-align: middle; }

.table .date {
  min-width: 62px;
  text-align: center; }

@media only screen and (max-width: 1000px) {
  .thead {
    display: none; }
  .tbody .tr {
    position: relative; }
  .table .details {
    min-width: 30%; }
  .table .paytype {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 20px;
    text-align: right; }
    .table .paytype img {
      height: 16px; }
  .table .date {
    display: block;
    padding-left: 20px;
    text-align: left; }
  .table .sum {
    position: absolute;
    top: 0;
    right: 0; } }

@media only screen and (max-width: 600px) {
  .payments section {
    padding-left: 10px;
    padding-right: 10px; } }

/* === Your Packages Cabinet module === */
.packages-your .paytype {
  text-align: center; }

.packages-your .details {
  min-width: 48%;
  line-height: 18px; }
  .packages-your .details-tariff {
    display: inline-block; }
  .packages-your .details .img-outer {
    display: inline-block;
    margin-right: 5px; }
  .packages-your .details img {
    height: 18px;
    vertical-align: text-bottom; }

.packages-your .hr {
  margin: 40px auto;
  width: 40%;
  height: 1px;
  border: none;
  border-top: 1px solid #ededed; }

.packages-your .buy {
  padding: 4px 24px;
  font-size: 16px; }

.packages-your .empty {
  width: 116px;
  box-sizing: content-box; }

.packages-your .outstanding .img-outer {
  position: relative; }
  .packages-your .outstanding .img-outer:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px; }

@media only screen and (max-width: 1000px) {
  .packages-your .details {
    position: relative;
    min-width: 100%; }
    .packages-your .details-tariff {
      position: absolute;
      right: 20px;
      top: 100%;
      line-height: 56px; }
  .packages-your .paytype {
    right: auto;
    left: 0;
    padding-right: 0;
    padding-left: 20px; }
    .packages-your .paytype:before {
      content: 'Remaining';
      display: inline-block;
      margin-right: 10px; }
  .packages-your .date:before {
    content: 'Till';
    display: inline-block;
    margin-right: 10px; }
  .packages-your .sum {
    position: static;
    float: right; } }

@media only screen and (max-width: 600px) {
  .payments section {
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (max-width: 560px) {
  .packages-your .tr .td {
    padding-top: 10px;
    padding-bottom: 10px; } }

/* === Calls Cabinet module === */
.cabinet.calls > .wrap {
  overflow: visible; }

.calls .table .details {
  min-width: 145px; }

.calls .table .date {
  min-width: 100px; }

.calls .table .sum {
  min-width: 91px; }

.calls .table .tbody .tr {
  cursor: pointer; }

.calls .thead .paytype {
  text-align: center; }

.calls .info-caption {
  padding: 20px 0;
  font-family: "helveticaneuecyr-light", sans-serif;
  font-size: 14px; }

.calls .pag-outer {
  min-height: 119px;
  padding: 0 25px; }
  .calls .pag-outer:after {
    content: '';
    display: table;
    clear: both; }

.calls .pagination {
  float: left;
  max-width: 75%;
  margin-top: 0;
  text-align: left; }

.calls .shown {
  float: right; }
  .calls .shown > span {
    font-family: "helveticaneuecyr-light", sans-serif; }
  .calls .shown .select-container {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    width: 40px;
    font-family: "helveticaneuecyr-light", sans-serif; }
    .calls .shown .select-container:after {
      top: 1px;
      right: 0px;
      width: 7px;
      height: 7px;
      content: '';
      display: block;
      position: absolute;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      -webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s; }
    .calls .shown .select-container .curr-value {
      display: inline-block;
      width: 100%;
      position: relative;
      z-index: 1; }
    .calls .shown .select-container .select {
      position: absolute;
      top: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      padding: 15px 0;
      box-shadow: 1px 1px 8px -1px #999;
      display: none; }
    .calls .shown .select-container .option {
      margin-bottom: 10px;
      padding: 0 20px;
      cursor: pointer; }
      .calls .shown .select-container .option:last-of-type {
        margin-bottom: 0; }
      .calls .shown .select-container .option a {
        color: #000; }

@media only screen and (max-width: 600px) {
  .calls section {
    padding-left: 10px;
    padding-right: 10px; }
  .calls .tr .td {
    padding-top: 10px;
    padding-bottom: 10px; } }

/* === Active state === */
/* === Active state === */
