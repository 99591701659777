/* === Payments Cabinet module === */


.payments{

  section{
    padding-left: 3.96%;

    h2{
      padding: 5.5% 0 2.4%;
      font-family: $ffr;
      font-size: 24px;
      text-align: left;
    }
  }

  .pagination{
    margin-top: 40px;
    margin-bottom: 70px;
    text-align: center;

    ul{
      margin: 0 auto;
      display: inline-block;
    }
    li{
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 0 10px;
      font-family: $ffr;
      font-size: 14px;
      color: #4b4b4b;
      text-align: center;
      line-height: 18px;
      border-radius: 9px;

      a{
        color: #4b4b4b;
      }
    }

    .current{
      background-color: $color;
      a{
        color: #fff;
      }
    }
  }
}

.thead{
  background-color: $color;
  font-family: $ffr;
  font-size: 0;
  color: #fff;
}

.tr{
  margin-bottom: 6px;
  font-size: 16px;
  text-align: justify;
  font-size: 0;

  &:after{
    content: '';
    display: inline-block;
    width: 100%;
  }

  .td{
    display: inline-block;
    padding: 15px 0;
    font-size: 16px;
    line-height: 26px;

    &:first-of-type{
      padding-left: 20px;
    }

    &:last-of-type{
      padding-right: 20px;
    }
  }
}

.tbody{
  width: 100%;

  .month{
    display: block;
    width: 100%;
    padding: 15px 20px;
    font-family: $ffl;
    color: #acacac;
  }

  .tr{
    box-shadow: 0px 1px 15px 0px #efefef;

    &:hover{
      background-color: #efefef;
    }
  }

  .td{
    font-family: $ffm;
    text-align: center;
    color: #4b4b4b;

    &:first-of-type{
      text-align: left;
    }

    &:last-of-type{
      text-align: right;
    }
  }
}

.table{

  .details{
    min-width: 30%;
  }

  .paytype{
    box-sizing: content-box;
    min-width: 93px;

    img{
      vertical-align: middle;
    }
  }

  .date{
    min-width: 62px;
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
  
  .thead{
    display: none;
  }

  .tbody{
    .tr{
      position: relative;
    }
  }

  .table{

    .details{
      min-width: 30%;
    }

    .paytype{
      position: absolute;
      right: 0;
      bottom: 0;
      padding-right: 20px;
      text-align: right;

      img{
        height: 16px;
      }
    }

    .date{
      display: block;
      padding-left: 20px;
      text-align: left;
    }

    .sum{
      position: absolute;
      top: 0;
      right: 0;
    }
  }

}

@media only screen and (max-width: 600px) {
  
  .payments section{
    padding-left: 10px;
    padding-right: 10px;
  }

}