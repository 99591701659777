/* === Downloads-page Main module === */

.downloads{

  h2{
    text-align: left;

    &:last-of-type{
      margin-top: 8%;
    }
  }

  .elem{
    margin-top: 4.25%;

    &:after{
      content: '';
      display: table;
      clear: both;
    }

    &.detected{
      .dl-btn{
        background-image: none;
        background-color: #ffa726;
      }
    }

    .title{
      margin-bottom: 25px;
      font-family: $ffm;
      font-size: 24px;
      font-weight: bold;
      color: $color;

      .fa{
        display: none;
      }
    }

  }

  .device-img{

    &.desk{
      float: right;
      width: 41%;
      text-align: right;
    }

    &.mob{
      float: left;
      width: 10%;
      margin-right: 8.3%;
    }

    img{
      max-width: 100%;
    }
  }

  .qr-code{
    width: 170px;
    height: 170px;
    margin-top: 10px;
    margin-right: 0;
    margin-left: 5%;
  }
}

@media screen and (max-width: 800px){
  .downloads{

    h2{
      text-align: center;
    }

    .elem{
      position: relative;
      margin-top: 7%;
      padding-bottom: 7%;
      overflow: hidden;

      &.detected{
        padding: 7%;
        border-radius: 9px;
        background-color: #63b832;
        margin-bottom: 62.5vw;

        overflow: visible;

        .title{
          margin-top: 40px;
          color: #fff;
        }

        .device-img{

          &.desk{
            margin-left: -130px;
            float: left;
          }
        }

        .text{
          position: absolute;
          top: 100%;
          color: #000;
        }
      }

      .title{
        margin-top: 20px;
        text-transform: capitalize;
        text-align: center;
        color: #000;

        span{
          display: none;
        }
        .fa{
          display: initial;
        }
      }

      &:nth-of-type(2), &:nth-of-type(3){
        &:before{
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
          height: 1px;
          background-color: #acacac;
        }
      }
    }

    .device-img{

      &.desk{
        float: none;
        width: 100%;
        text-align: center;
      }

      &.mob{
        float: none;
        width: 100%;
        margin-right: 0;
        text-align: center;
      }

      img{
        max-width: 100%;
      }
    }



    .qr-code{
      display: none;
    }
  }

  .dl-btn{
    width: 195px;
    height: 67px;
    font-size: 21px;
    line-height: 67px;
  }
}