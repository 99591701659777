/* === Modal pay module === */

.modal-pay-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: scroll;
    z-index: 3;
    display: none;
}

.modal-pay{
    position: absolute;
    top: 20vw;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    min-width: 480px;
    padding: 5vw 4vw;
    background-color: #fff;
    border: 1px solid #acacac;
    border-radius: 9px;
}