
/* === Base styles === */

html, body{
  font-size: 16px;
}

div, section, input[type="text"], input[type="email"], input[type="password"]{
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

.clear:after{
  content: '';
  display: table;
  clear: both;
}

.icon-right-fontello{
  font-size: 14px;
}

.dl-btn{
  display: inline-block;
  width: 150px;
  height: 45px;
  margin-top: 18px;
  font-family: $ffm;
  font-size: 18px;
  line-height: 46px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-image: -webkit-linear-gradient(to bottom, #000 0%, #000 100%);
  background-image: -moz-linear-gradient(to bottom, #000 0%, #000 100%);
  background-image: -o-linear-gradient(to bottom, #000 0%, #000 100%);
  background-image: linear-gradient(to bottom, #000 0%, #000 100%);
  border-radius: 9px;

  &:hover{
    background-image: -webkit-linear-gradient(to bottom, #313131 0%, #000 100%);
    background-image: -moz-linear-gradient(to bottom, #313131 0%, #000 100%);
    background-image: -o-linear-gradient(to bottom, #313131 0%, #000 100%);
    background-image: linear-gradient(to bottom, #313131 0%, #000 100%);
  }

  &:active{
    background-image: -webkit-linear-gradient(to top, #313131 0%, #000 100%);
    background-image: -moz-linear-gradient(to top, #313131 0%, #000 100%);
    background-image: -o-linear-gradient(to top, #313131 0%, #000 100%);
    background-image: linear-gradient(to top, #313131 0%, #000 100%);
  }

  &.android{
    p{
      width: 100%;
      height: 100%;
      background: transparent url(../img/google-play.png) center no-repeat;
      background-size: 100%;
    }
  }

  &.ios{
    p{
      width: 100%;
      height: 100%;
      background: transparent url(../img/btn_dl-ios.png) center no-repeat;
      background-size: 100%;
    }
  }

  &-outer{
    display: inline-block;
  }
}



.radio{

  input{
    display: none;
  }

  label{
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    font-family: $ffl;
    color: #aaafae;
  }

  .circle{
    display: inline-block;
    margin-bottom: -3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #acacac;
    padding: 2px;

    &:before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: tr;
    }
  }

  input:checked+label{
    color: #000;

    & .circle:before{
      background-color: $color;
    }
  }
}

