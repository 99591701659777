/* === Page module === */

.page{
  min-height: 100vh;
  background-color: #efefef;

  &.cabinet{
    background-color: #fff;
  }

  .text{
    font-family: $ffr;
    font-size: 16px;
    line-height: 1.2;
    color: #666;
    // color: #b3b3b3;

    & p{
      font-family: $ffr;
      font-size: 16px;
      line-height: 1.2;
      color: #666;
      // color: #b3b3b3;

      a{
        color: $color;
      }
    }
  }
}