/* === About-page Main module === */

.policy{
  padding-top: 35px;
  padding-bottom: 70px;

  ul{
    padding-bottom: 20px;
  }

  li a{
    display: inline-block;
    font-family: $ffr;
    font-size: 18px;
    line-height: 34px;
    color: #0ba3ec;
    text-decoration: underline;
  }

  h2{
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-family: $ffm;
    font-size: 24px;
    text-transform: uppercase;

    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: #b3b3b3;
    }
  }

  .text p{
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 800px) {

  .policy{

    li a{
      margin-top: 15px;
      text-align: center;
    }

    h2{
      text-align: center;
      text-transform: none;

      &:after{
        left: 50%;
        margin-left: -50px;
      }
    }

    .text{
      font-size: 21px;
      text-align: justify;
    }
  }

}