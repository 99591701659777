/* === Cabinet-page Main module === */

.cabinet{

  .wrap{
    padding-left: 0;
    padding-right: 0;
  }

  & > .wrap{
    position: relative;
    overflow: hidden;
  }

  &-menu{
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 240px;
    height: 100%;
    min-height: calc(100vh - 62px);
    background-color: #4b4b4b;
    // transition: width .5s;

    li{
      white-space: nowrap;
      overflow: hidden;
      border-bottom: 1px solid #acacac;

      &.selected{
        background-color: #303030;

      .icon{

        &-1{
          background: transparent url(../img/cabinet/avatar_active.png) 0 0 no-repeat;
          background-size: 100%;
          width: 55px;
          height: 55px;

          &+span{
            margin-bottom: 0;
            line-height: 55px;
            transition: margin-bottom .35s;
          }
        }

        &-2{
          background-position: -5px -28px;
        }

        &-3{
          background-position: -33px -57px;
        }

        &-4{
          background-position: -5px -84px;
        }

        &-5{
          background-position: -61px -28px;
        }
      }

        span{
          color: #fff;
          transition: width .5s;
        }
      }

      a{
        display: inline-block;
        padding: 20px 0 20px 20px;
      }
    }

    span{
      display: inline-block;
      font-family: $ffr;
      font-size: 16px;
      line-height: 23px;
      color: #acacac;
      white-space: nowrap;
      vertical-align: bottom;
    }

    .icon{
      display: inline-block;
      height: 23px;
      width: 23px;
      background: url('../img/cabinet/cabinet-menu-sprite.png') no-repeat;
      vertical-align: bottom;


      &-1{
         background-position: -5px -0px;
      }

      &-2{
        background-position: -61px -0px;
      }

      &-3{ 
        background-position: -5px -56px;
      }

      &-4{
        background-position: -61px -57px;
      }

      &-5{
        height: 24px;
        background-position: -33px -28px;
      }
    }

    &.is-collapsed{
      width: 66px;
      // transition: width .5s;

      li{

        &.selected{

          .icon-1{
            width: 23px;
            height: 23px;
            background: url('../img/cabinet/cabinet-menu-sprite.png') no-repeat;
            background-position: -33px -0px;

            &+span{
              margin-bottom: -28px;
              // line-height: 23px;
            }
          }
        }

        span{
          overflow: hidden;
          width: 0;
        }
      }
    }

    // .icon{
    //   display: inline-block;
    //   height: 23px;
    //   width: 23px;
      

    //   &-1{
    //     background: transparent url(../img/cabinet/avatar.png) 0 0 no-repeat;
    //   }

    //   &-2{
    //     background: transparent url(../img/cabinet/calls.png) 0 0 no-repeat;
    //   }

    //   &-3{
    //     background: transparent url(../img/cabinet/payments.png) 0 0 no-repeat;
    //   }

    //   &-4{
    //     background: transparent url(../img/cabinet/packages.png) 0 0 no-repeat;
    //   }

    //   &-5{
    //     background: transparent url(../img/cabinet/cart.png) 0 0 no-repeat;
    //     height: 24px;
    //   }
    // }

    // &.is-active{
    //   width: 240px;

    //   .icon{

    //     &-1{
    //       background: transparent url(../img/cabinet/avatar_active.png) 0 0 no-repeat;
    //       background-size: 100%;
    //       width: 55px;
    //       height: 55px;

    //       &+span{
    //         line-height: 55px;
    //       }
    //     }

    //     &-2{
    //       background: transparent url(../img/cabinet/calls_active.png) 0 0 no-repeat;
    //     }

    //     &-3{
    //       background: transparent url(../img/cabinet/payments_active.png) 0 0 no-repeat;
    //     }

    //     &-4{
    //       background: transparent url(../img/cabinet/packages_active.png) 0 0 no-repeat;
    //     }

    //     &-5{
    //       background: transparent url(../img/cabinet/cart_active.png) 0 0 no-repeat;
    //     }
    //   }
    // }
  }

  .main{
    float: right;
    width: calc(100% - 240px);
    // width: calc(100% - 66px);
    min-height: calc(100vh - 153px);
    // transition: width .5s;

    &.is-wide{
      width: calc(100% - 66px);
    }
  }

  .login-block{
    margin-top: 5%;

    h1{
      font-family: $ffr;
      font-size: 24px;
      line-height: 55px;
    }

    .outer > *{
      display: block;
      width: 224px;
      margin: 0 auto 3px;
    }

    label{
      font-family: $ffl;
      font-size: 16px;
      text-align: left;
    }

    input{
      width: 100%!important;
      box-sizing: border-box;
      padding: 7px;
      border: 1px solid $color;
      border-radius: 9px;
    }

    button{
      padding: 9px 0;
      color: #fff;
      background-color: $color;
      border-radius: 9px;
      border: none;

      &#goLogin{
        margin-top: 18px;
      }
    }
  }
}


@media only screen and (max-width: 600px) {

  .cabinet{

    &-menu{
      position: relative;
      width: 100%;
      height: auto;
      min-height: 0px;
      overflow: hidden;

      display: none;

      &.is-collapsed{
        width: 100%;

        li{

          span{
            width: auto;
          }

          &.selected{

          .icon-1+span{
              margin-bottom: 0;
            }
          }

        }
      }

      li{
        a{
          display: inline-block;
          width: 100%;
        }
      }
    }

    .main{
      width: 100%;

      &.is-wide{
        width: 100%;
      }
    }
  }

}