/* === FAQ-page Main module === */

.faq-h{

  .bg{
    display: none;

    position: relative;
    width: 100%;
    height: 55vw;
    max-height: 100vh;
    margin-top: -134px;
    padding-top: 134px;
    background: transparent url('../img/faq-hallway.jpg') center no-repeat;
    background-size: cover;


    // @media screen and (min-width: 1400px){
    //   &{
    //     max-height: 88vh;
    //   }
    // }


    // @media screen and (max-width: 1399px){
    //   &{
    //     max-height: 85vh;
    //   }
    // }


    // @media screen and (max-width: 1000px){
    //   &{
    //     max-height: auto;
    //   }
    // }


    @media screen and (max-width: 800px){
      &{
        margin-top: -90px;
        padding-top: 90px;
      }
    }


    @media screen and (max-width: 320px){
      &{
        height: 72vw;
        background-size: 370px;
      }
    }
  }

  h1{
    padding-top: 80px;
    font-family: $ffl;
    font-size: 60px;
    line-height: 55px;

    span{
      color: $color;
    }

    @media screen and (max-width: 1000px){
      &{
        padding-top: 8vw;
      }
    }

    @media screen and (max-width: 640px){
      &{
        font-size: 50px;
      }
    }

    @media screen and (max-width: 480px){
      &{
        font-size: 40px;
        line-height: 1.2;
      }
    }

    @media screen and (max-width: 414px){
      &{
        padding-top: 10px;
        font-size: 36px;
      }
    }

    @media screen and (max-width: 320px){
      &{
        padding-top: 10px;
        font-size: 36px;
      }
    }
  }

  h2{
    margin: 40px 0;
    font-family: $ffl;
    font-size: 36px;
    line-height: 55px;
    text-align: center;
  }

  .row{
    @include container();

    padding-bottom: 70px;
  }

  .col{
    @include span(6 of 12 0);

    text-align: center;

    img{
      max-width: 100%;
    }
  }

  .caption{
    margin-top: 18px;
    font-family: $ffr;
    font-size: 21px;
    color: #000;
  }

  .wrap > h2{
    // font-size: 0;

    @media screen and (max-width: 800px){
      &{
        font-size: 36px;
      }
    }
  }
}

.title-double{
  display: none;

  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 3vw;

  @media screen and (max-width: 800px){
    &{
      display: none;
    }
  }

}

.down-arrow{
  margin-top: -30px;
  text-align: center;

  .fa{
    font-size: 46px;
    color: $color;
  }
}

