/* === About-page Main module === */

.about{
  padding-top: 35px;
  padding-bottom: 70px;

  .col{

    &-1{
      @include span(7 of 12 0);

      img{
        max-width: 100%;
      }
    }

    &-2{
      @include span(5 of 12 0);
      padding-left: 20px;
    }
  }

  .up-title{
    margin-bottom: 12px;
    font-family: $ffr;
    font-size: 16px;
    color: #0ba3ec;
    text-transform: uppercase;
  }

  h1{
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: $ffa;
    font-size: 45px;
    text-transform: uppercase;

    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: #b3b3b3;
    }
  }

  .text{

    p{
      margin-bottom: 1rem;
    }

    ul{
      margin-bottom: 1rem;
      padding-left: 20px;
      list-style-type: disc;

      li{
        font-family: $ffr;
        font-size: 16px;
      }
    }

    a{
      color: #0ba3ec;
    }
  }
}

@media screen and (max-width: 800px) {

  .about{
    padding-top: 35px;
    padding-bottom: 70px;

    .col{

      &-1{
        @include span(12 of 12 0);

        img{
          max-width: 100%;
        }
      }

      &-2{
        @include span(12 of 12 0);
        margin-top: -150px;
        padding-left: 0px;
        background-color: rgba(239, 239, 239, .75);
      }
    }

    .up-title{
      margin-top: 30px;
      text-align: center;
    }

    h1{
      text-align: center;
      text-transform: none;

      &:after{
        left: 50%;
        margin-left: -50px;
      }
    }

    .text{
      font-size: 21px;
    }
  }

}