/* === Offer-page Main module === */

.offer{
  text-align: center;

  h2{
    padding: 30px 0 5px;
    font-family: $ffl;
    font-size: 36px;
    line-height: 55px;
  }

  .sub-title{
    padding-bottom: .5rem;
    font-family: $ffr;
    font-size: 24px;
    line-height: 55px;
  }

  .elem{
    float: left;
    width: 32%;
    margin-right: 1.99%;
    margin-bottom: 50px;
    padding-bottom: 40px;
    text-align: center;
    border-radius: 9px;
    overflow: hidden;
    background-color: #fff;

    &:nth-of-type(3n){
      margin-right: 0;
    }
  }

  .country{
    padding: 17px 17px;
    text-align: left;
    background-color: #317768;

    &:after{
      content: '';
      display: table;
      clear: both;
    }

    &-name{
      position: relative;
      top: 30px;
      transform: translateY(-50%);
      font-family: $ffl;
      font-size: 27px;
      line-height: 32px;
      color: #fff;
    }

    img{
      float: left;
      margin-right: 10px;
    }
  }

  .price{
    margin: 40px auto 0;
    font-family: $ffa;
    font-size: 60px;

    &-old{
      position: relative;
      display: inline-block;
      margin: 16px auto;
      font-family: $ffo;
      font-size: 18px;

      // &:before{
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: 50%;
      //   left: -5%;
      //   width: 110%;
      //   height: 1px;
      //   background-color: #acacac;
      // }
    }
  }

  .buy{
    display: inline-block;
    padding: 20px 46px;
    font-family: $ffm;
    font-size: 21px;
    color: #fff;
    background-color: #ffa726;
    border: none;
    border-radius: 9px;
  }
}

.search-tariff{
  display: inline-block;
  max-width: 100%;
  padding: 4% 8%;
  background-color: #fff;
  border-radius: 9px;
  overflow: hidden;

  input{
    box-sizing: border-box;
    width: 450px;
    max-width: calc(100% - 35px);
    padding: 10px;
    font-family: $ffl;
    font-size: 18px;
    border: 1px solid #000;
    border-radius: 9px;
    outline: none;
    z-index: 3;
    &::-webkit-input-placeholder {
      
    }
    &::-moz-placeholder {
      
    }
    &:-moz-placeholder {
      
    }
    &::-ms-input-placeholder {
      
    }
  }

  .btn-search{
    font-size: 20px;
    background: none;
    border: none;
  }

  hr{
    position: relative;
    left: -25%;
    width: 150%;
    margin: 7% 0;
    @media screen and (max-width: 800px){
      &{
        left: auto;
        width: auto;
      }
    }
  }
}

.drop{
  position: absolute;
  width: 450px;
  max-width: calc(100% - 35px);
  max-height: 177px;
  margin-top: 44px;
  border: 1px solid #000;
  border-radius: 9px;
  background-color: #fff;
  z-index: 2;
  overflow-y: auto;
  // overflow-y: scroll;

  display: none;

  @media screen and (max-width: 600px){
    &{
      width: 100%;
      max-width: calc(100% - 8vw);
    }
  }

  ul{

  }

  li{
    margin: 3px 5px;
    padding: 0 5px;
    text-align: left;

    &:hover{
      background-color: $color;
      cursor: pointer;

      & *{
        color: #fff;
      }
    }
  }

  .img-outer{
    display: inline-block;
    width: 25px;

    img{
      width: 100%;
      vertical-align: bottom;
    }
  }

  .name{
    display: inline-block;
    margin-left: 7px;
    color: #4e4e4e;

    span{
      line-height: 25px;
      font-family: $ffl;
      font-size: 18px;
    }
  }

  .code{
    display: inline-block;
    margin-left: 7px;
    line-height: 25px;
    font-family: $ffl;
    font-size: 18px;
    color: #a8a8a8;
  }
}

.result{
  position: relative;
  width: 100%;
  // padding-top: 8%;

  .result-item{
    position: relative;
    margin-bottom: 30px;

    &:after{
      content: '';
      display: table;
      clear: both;
    }

    .result-name{
      font-size: 16px;

      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }

  img{
    float: left;
    max-height: 61px;
    margin-right: 10px;
  }

  &-name, &-price{
    font-family: $ffo;
    font-size: 24px;
    text-align: left;
  }

  &-price{
    margin-top: 19px;
    font-size: 18px;

  }

  &-buy{
    position: absolute;
    right: 0;
    bottom: 0px;
    // bottom: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffa726;
  }
}


@media screen and (max-width: 800px){
  .offer{

    .sub-title{
      padding-bottom: 1.5rem;
      font-family: $ffr;
      font-size: 24px;
      line-height: 24px;
    }

    .search-tariff{
      position: relative;
      padding: 4% 4%;
      overflow: visible;

      input{
        width: 450px;
        max-width: 100%;
        padding-right: 40px;
        font-size: 16px;
        &::-webkit-input-placeholder {
          
        }
        &::-moz-placeholder {
          
        }
        &:-moz-placeholder {
          
        }
        &::-ms-input-placeholder {
          
        }
      }

      .btn-search{
        position: absolute;
        top: 4.9vw;
        right: 6%;
        // transform: translateY(-50%);
        font-size: 20px;
        color: #7f7f7f;
      }
    }

    .elem{
      width: 100%;
      margin-right: 0;
    }

    .price{
      margin: 40px auto 0;
      font-family: $ffa;
      font-size: 60px;

      &-old{

      }
    }

    .buy{
      
    }
  }
}



@media screen and (width: 640px){
  .offer{

    .search-tariff{

      .btn-search{
        top: 3.9vw;

        .is-wide &{
          top: 4.9vw;
          right: 10%;
        }
      }
    }
  }
}


@media screen and (max-width: 600px){
  .packages{
    .offer{

      .search-tariff{

        input{
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px){
  .offer{

    .search-tariff{

      .btn-search{
        top: 5.6vw;
      }
    }

    .elem{
      position: relative;
      width: 100%;
      margin-right: 0;
      text-align: left;
    }

    .price{
      float: left;
      width: 100%;
      margin-left: 10px;

      &-old{
        float: left;
        margin-left: 15px;
      }
    }

    .buy{
      position: absolute;
      right: 10px;
      top: calc(50% + 47px);
      transform: translateY(-50%);
      padding: 20px 8.125vw;
      font-family: $ffm;
      font-size: 21px;
      color: #fff;
      background-color: #ffa726;
      border: none;
      border-radius: 9px;
    }

    .result{

      &-buy{
        position: static;
        transform: none;
        margin-top: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 320px){
  .offer{

    .search-tariff{

      input{
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          // color: transparent;
          font-size: 13px;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          // color: transparent;
          font-size: 13px;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          // color: transparent;
          font-size: 13px;
        }
        &:-moz-placeholder { /* Firefox 18- */
          // color: transparent;
          font-size: 13px;
        }
      }
    }
  }
}