/* === Grid === */

.wrapper{
  @include container();

  width: 100%;
  overflow: hidden;
  // min-width: 1024px;
  // max-width: 1200px;


//   @media screen and (max-width: 1024px) {
//     margin-right: 1.46484375%;
//     margin-left: 1.46484375%;
//   }

  &.cabinet{
    display: none;
  }
}

.wrap{
  @include container();

  min-width: 1000px;
  max-width: 1000px;
  padding-left: 15px;
  padding-right: 15px;

  // @include span(12 of 12);

  // margin-left: 3.15457413249211%;
  // margin-right: 3.15457413249211%;

  // @media screen and (min-width: 1000px) {
  //   margin-right: 1.46484375%;
  //   margin-left: 1.46484375%;
  // }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}

@media screen and (max-width: 1000px) {
  .wrap{
    min-width: 100%;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}