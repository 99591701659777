/* === Your Packages Cabinet module === */

.packages-your{

  .paytype{
    text-align: center;
  }


  .details{
    min-width: 48%;
    line-height: 18px;

    &-tariff{
      display: inline-block;
    }

    .img-outer{
      display: inline-block;
      margin-right: 5px;
    }

    img{
      height: 18px;
      vertical-align: text-bottom;
    }
  }

  .hr{
    margin: 40px auto;
    width: 40%;
    height: 1px;
    border: none;
    border-top: 1px solid #ededed;
  }

  .buy{
    padding: 4px 24px;
    font-size: 16px;
  }

  .empty{
    width: 116px;
    box-sizing: content-box;
  }

  .outstanding{

    .img-outer{
      position: relative;

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 3px;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {

  .packages-your{
    
  }

}

@media only screen and (max-width: 1000px) {

  .packages-your{

    .details{
      position: relative;
      min-width: 100%;

      &-tariff{
        position: absolute;
        right: 20px;
        top: 100%;
        line-height: 56px;
      }
    }

    .paytype{
      right: auto;
      left: 0;
      padding-right: 0;
      padding-left: 20px;

      &:before{
        content: 'Remaining';
        display: inline-block;
        margin-right: 10px;
      }
    }

    .date{

      &:before{
        content: 'Till';
        display: inline-block;
        margin-right: 10px;
      }
    }

    .sum{
      position: static;
      float: right;
    }
  }

}

@media only screen and (max-width: 600px) {
  
  .payments section{
    padding-left: 10px;
    padding-right: 10px;
  }

}

@media only screen and (max-width: 560px) {

  .packages-your{

    .tr{

      .td{
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

}

