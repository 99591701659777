/* === Header_Cabinet module === */

.cabinet{
  .header{
    // margin-bottom: 94px;

    &-top{
      padding: 0;
      border-bottom: 1px solid #acacac;
    }

    .cabinet-sandwich{
      position: absolute;
      top: 0%;
      left: 0px;
      width: 66px;
      height: 100%;
      z-index: 2;
      border-left: 1px solid #acacac;
      border-right: 1px solid #acacac;

      .inner{
        position: absolute;
        top: 50%;
        left: 21px;
        margin-top: -1px;
        width: 24px;
        height: 2px;
        background-color: #acacac;

        &:before, &:after{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #acacac;
        }

        &:before{
          top: 7px;
        }

        &:after{
          bottom: 7px;
        }
      }
    }

    .logo{
      height: 48px;
      margin: 7px 0 7px 80px;
      background-size: contain;
    }

    .mob-logout{
      position: absolute;
      top: 50%;
      right: 0;
      transform:translateY(-50%);

      .logout{
        display: inline-block;
        padding-right: 10px;
        font-family: $ffl;
        font-size: 16px;
        color: #4b4b4b;
      }

      @media screen and (min-width: 1000px) {
        &{
          display: none;
        }
      }

      @media screen and (max-width: 800px) {
        &{

          .logout{
            color: #fff;
          }
        }
      }

      @media screen and (max-width: 480px) {
        &{
          .logout{
            padding-right: 5px;
          }
        }
      }
    }

    .to-right{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      & > span{
        display: none;
        margin-left: 2.5vw;
        font-family: "helveticaneuecyr-roman", sans-serif;
        font-size: 30px;
      }
    }

    .btn-add-funds{
      display: block;
      float: left;
      margin-right: 2.5vw;
      padding: 8px 34px;
      font-family: "helveticaneuecyr-roman", sans-serif;
      font-size: 16px;
      color: #fff;
      background-color: #ffa726;
      border: none;
      border-radius: 9px;
    }

    .ballance{
      float: right;
      margin-top: 5px;

      & > span, a{
        font-family: $ffl;
        font-size: 16px;
        color: #4b4b4b;
      }
    }

    .value{
      display: inline-block;
      margin-right: 3.5vw;
      font-family: $ffr;
      font-size: 21px;
    }

    .logout{
      display: inline-block;

      img{
        vertical-align: top;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {

  .cabinet{

    .header{

      & + .wrap{
        margin-top: 54px;
      }

      .to-right{
        position: absolute;
        top: 65px;
        left: 0;
        padding: 10px 0;
        background-color: #fff;
        transform: translateY(0);

        & > span{
          float: left;
          display: initial;
        }
      }

      .btn-add-funds{
        float: right;
      }

      .ballance{
        & > span{
          display: none;
        }
      }

      .logout{
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 800px) {

  .cabinet{

    .header{

      .logo{
          margin-left: 0;
      }

      .cabinet-sandwich{

        .inner, .inner:before, .inner:after{
          background-color: #fff;
        }
      }

      .to-right{

        & > span{
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .cabinet{

    .header{

      .to-right{

        & > span{
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 560px) {

  .cabinet{

    .header{

      .btn-add-funds{
          padding: 8px 16px;

        span{
          display: none;
        }
      }
    }
  }
}