/* === Calls Cabinet module === */

.cabinet.calls > .wrap {
  overflow: visible;
}

.calls{


  .table{
    .details{
      min-width: 145px;
    }
    .date{
      min-width: 100px;
    }
    .sum{
      min-width: 91px;
    }

    .tbody{
      .tr{
        cursor: pointer;
      }
    }
  }

  .thead{

    .paytype{
      text-align: center;
    }
  }

  .info-caption{
    padding: 20px 0;
    font-family: $ffl;
    font-size: 14px;
  }

  .pag-outer{
    min-height: 119px;

    padding: 0 25px;

    &:after{
      content: '';
      display: table;
      clear: both;
    }
  }

  .pagination{
    float: left;
    max-width: 75%;
    margin-top: 0;
    text-align: left;
  }

  .shown{
    float: right;

    &> span{
      font-family: $ffl;
    }

    .select-container{
      position: relative;
      display: inline-block;
      margin-left: 10px;
      width: 40px;
      
      font-family: $ffl;


      &:after{
        top: 1px;
        right: 0px;
        width: 7px;
        height: 7px;
        content: '';
        display: block;
        position: absolute;
        border-top: 1px solid #000;
        border-left: 1px solid #000;
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
      }

      .curr-value{
        display: inline-block;
        width: 100%;

        position: relative;
        z-index: 1;
      }

      .select{
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        padding: 15px 0;
        box-shadow: 1px 1px 8px -1px #999;

        display: none;
      }

      .option{
        margin-bottom: 10px;
        padding: 0 20px;
        cursor: pointer;

        &:last-of-type{
          margin-bottom: 0;
        }

        a{
          color: #000;
        }
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  
  .calls{
    
    section{
      padding-left: 10px;
      padding-right: 10px;
    }

     .tr{

      .td{
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }


}