/* === Header module === */

.header{
  background-image: -webkit-linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);
  background-image: -moz-linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);
  background-image: -o-linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);
  background-image: linear-gradient(170deg, #2d6d64 0%, #2d6d64 36%, #2bb7a7 100%);

  &-top{
    padding: 36px 0;
    // padding: 3.90625vh 0;

    @media screen and (max-width: 320px) {
      &{
        padding: 1.953125vh 0;
      }
    }
  }

  .wrap{
    position: relative;
  }

  & > .wrap{
    height: calc(100% - 122px);
  }

  .logo{
    float: left;
    width: calc(17.8% + 6px);
    height: 62px;
    background: transparent url(../img/logo.png) 0 0 no-repeat;

    &-box{
      float: left;
    }
  }

  .top-slider{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    overflow: hidden;

    .slide-box{
      
      & > li{
        position: relative;
        height: calc(100vh - 122px);

        img{
          max-height: calc(100vh - 122px - 100px);
        }
      }
    }

    .slide-img{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .bullets{
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      li{
        float: left;
        margin: 0 6px;
        width: 8px;
        height: 8px;
        background-color: rgba(255, 255, 255, .5);
        border-radius: 50%;
        transition: background .3s;

        &.selected{
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  &-content{
    @include span(5 of 12 0);
    @include push(7);

    // position: absolute;
    // top: 0;
    // right: 0;
    position: relative;
    height: 100%;
    color: #fff;

    .inner{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    h1{
      margin-top: 5.2vh;
      font-family: $ffa;
      font-size: 60px;
    }

    .sub-title{
      margin-top: 3.25vh;
      font-family: $ffr;
      font-size: 21px;
    }

    .download{
      display: inline-block;
      margin: 5.2vh 0 3.25vh;
      padding: 17px 48px;
      font-family: $ffm;
      font-size: 24px;
      color: #fff;
      text-transform: uppercase;
      background-color: #ffa726;
      border-radius: 9px;
    }

    .features{
      li{
        padding: 3px 0;
        font-family: $ffr;
        font-size: 18px;
      }
    }

    .bottom-text{
      margin-top: 11.71875vh;
      border-top: 1px solid rgba(255, 255, 255, .4);

        padding-top: 10px;
        font-family: $ffr;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        opacity: .75;
      p{
        padding-top: 10px;
        font-family: $ffr;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        opacity: .75;
      }
    }

  }
}

.menu{
  float: left;
  width: calc(100% - 0px);
  // width: calc(100% - 60px);
  text-align: justify;

  &:after{
    content: '';
    display: inline-block;
    width: 100%;
  }

  &-nav{
    @include span(7 of 12 0);
    float: right;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    .lang{
      min-width: 30px;
      float: right;
      font-family: $ffr;
      font-size: 16px;

      display: none;

      .language-chooser{
        position: absolute;
        right: 0;

        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 0;
          width: 6px;
          height: 6px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
        }

        li{
          margin-bottom: 5px;
          display: none;
        }

        span{
          display: inline-block;
          min-width: 30px;
          color: #fff;
        }

        .active{
          display: block;
        }
      }
    }
  }

  &-item{
    display: inline-block;

    a{
      font-family: $ffr;
      font-size: 16px;
      color: #fff;
    }
  }
}

.page{

  .header{
    background-image: none;
    height: auto;
    position: relative;
    z-index: 1;

    .logo{
      background: transparent url(../img/logo-green.png) 0 0 no-repeat;
    }
  }
  
  .menu{

    &-nav{

      .lang{
        
        .language-chooser{


          span{
            display: inline-block;
            min-width: 30px;
            color: #000;
          }

          &:before{
            border-bottom: 1px solid #000;
            border-right: 1px solid #000;
          }
        }
      }
    }

    &-item{

      a{
        color: #000;
      }

      &.current-menu-item{
        a{
          color: #2e7666;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) and (max-height:700px) {
  .header{

    &-content{
      
      .bottom-text{
        margin-top: 2.75vh;
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .header{

    &-content{
      margin-left: 57%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .header{
    text-align: center;

    & > .wrap{
      padding: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .header{

    .logo{
      width: 178px;
    }
  }
}



@media screen and (max-width: 800px) {
  .header{
    text-align: center;
    background-color: rgba(45, 109, 100, 1);
    background-image: none;

    & > .wrap{
      min-width: calc(100% - 20px);
      box-sizing: border-box;
    }

    .logo{
      float: none;
      display: inline-block;
      width: 178px;
    }

    .top-slider{

      .slide-img{
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .bullets{
        bottom: 75px;
        transform: translateX(-50%);
        // transform: translateX(-64%);
        // z-index: 2;

        li{
          margin: 0 10px;
          width: 10px;
          height: 10px;
        }
      }
    }

    &-content{
      @include span(12 of 12 0);
      @include push(0);
      // background-image: linear-gradient(to bottom, 0%  rgba(45, 109, 100, .0),  rgba(45, 109, 100, .85));

      h1{
        font-size: 50px;
      }

      .inner{
        top: auto;
        bottom: 12px;
        transform: translateY(0%);
        background-color: rgba(45, 109, 100, .75);

        .bottom-text{
          margin-top: 0;
        }
      }
    }
  }

  .page{
    .header{

      &-top{
        padding: 1.953125vh 0;
      }

      .logo{
        max-height: 50px;
        background: transparent url(../img/logo.png) center no-repeat;
        background-size: contain;
      }
    }
  }

  .menu-nav{
    float: none;
    right: auto;
    top: -3.90625vh;
    left: 0;
    transform: translateY(0);
    width: 360px;
    padding-top: 90px;
    padding-left: 20px;
    background-color: rgba(75, 75, 75, .85);
    z-index: 1;

    display: none;

    ul{
      width: auto;
    }

    li.menu-item{
      width: 100%;
      min-width: 340px;
      margin-bottom: 50px;

       a{
        font-size: 24px;
        color: #acacac;
      }
    }

    .lang{
      float: none;
      position: relative;
      left: -10px;
      width: 294px;
      margin: 0 auto 90px;
      clear: left;
      border: 1px solid #cacaca;
      border-radius: 9px;

      &:after{
        content: '';
        display: table;
        clear: both;
      }

      // &:before{
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   left: 50%;
      //   width: 1px;
      //   height: 100%;
      //   background-color: #cacaca;
      // }

      .language-chooser{
        position: static;

        &:before{
          display: none;
        }

        li{
          float: left;
          width: 25%;
          margin-bottom: 0;
          font-size: 24px;
          line-height: 42px;
          text-transform: lowercase;

          display: block;

          &:not(.active){
            span{
              color: #acacac;
            }
          }

          &.active{
            background-color: #303030;

            span{
              color: #fff;
            }

            &:first-child{
              border-bottom-left-radius: 9px;
              border-top-left-radius: 9px;
            }

            &:last-child{
              border-bottom-right-radius: 9px;
              border-top-right-radius: 9px;
            }
          }
        }
      }
    }
  }

  .sandwich{
    position: absolute;
    top: 50%;
    left: 20px;
    width: 38px;
    height: 24px;
    transform: translateY(-55%);
    z-index: 2;

    .inner{
      position: relative;
      margin-top: 12px;
      height: 2px;
      width: 100%;
      background-color: #fff;
      transition: background-color 0.3s;

      &:before, &:after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: #fff;
        transition: all .3s;
        transition-duration: 0.3s, 0.3s;
        transition-delay: 0.3s, 0s;
      }

      &:before{
        bottom: 11px;
        transition-property: bottom, transform;
      }

      &:after{
        top: 11px;
        transition-property: top, transform;
      }

      &.is-active{
        background-color: transparent;

        &:before{
          bottom: 0;
          transform: rotate(45deg);
        }

        &:after{
          top: 0;
          transform: rotate(-45deg);
        }

        &:before, &:after{
          transition-delay: 0s, 0.3s;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) and (orientation: portrait) {
  .header{

    & > .wrap{
      height: calc(100% - 137px);
    }

    &-content{

      h1{
        margin-top: 1vh;
      }

      .inner{
        padding-bottom: 40px;

        .bottom-text{

        }
      }

      .features{
        li{
          font-size: 16px;
        }
      }
    }

    .top-slider{

      .slide-box{
        & > li{
          height: calc(100vh - 137px);
        }
      }

      .bullets{
        bottom: 35px;
      }
    }
  }
}

@media screen and (max-width: 800px) and (orientation: landscape) {
  .header{

    & > .wrap{
      height: calc(100% - 85px);
    }

    &-top{
      padding: 10px;
    }

    &-content{

      h1{
        margin-top: 1vh;
        
      }

      .inner{
        padding-bottom: 30px;

        .features{
          // display: none;
          li{
            font-size: 15px;
          }
        }

        .bottom-text{
          // display: none;
          p{
            padding-top: 0;
          }
        }
      }

      .features{
        li{
          font-size: 16px;
        }
      }
    }

    .top-slider{

      .slide-box{
        & > li{
          height: calc(100vh - 85px);
        }
      }

      .bullets{
        bottom: 25px;
      }
    }
  }
}


@media screen and (max-width: 667px) and (orientation: landscape) {
  .header{

    &-top{
      padding: 7px 0;
    }

    & > .wrap{
      height: calc(100% - 79px);
    }

    &-content{

      h1{
        font-size: 38px;
      }

      .inner{
        padding-bottom: 30px;
      }

      .download{
        margin: 5px 0;
        padding: 12px 45px;
      }

      .features{
        li{
          font-size: 14px;
        }
      }
      .bottom-text{
        p{
          font-size: 13px;
          line-height: 1;
        }
      }
    }

    .top-slider{

      .slide-box{
        & > li{
          height: calc(100vh - 79px);

          img{
            max-height: calc(100vh - 130px)
          }
        }
      }

      .bullets{
        bottom: 25px;
        transform: translateX(-50%);
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .header{
    &-content{
      p, li, h1{
        text-align: left;
      }
    }
    .top-slider .slide-box li img{
      max-width: calc(100vw - 20px);
    }
  }
}

@media screen and (max-width: 568px) and (orientation: landscape) {
  .header{

    &-content{

      h1{
        font-size: 30px;
      }

      .sub-title{
        margin-top: 2vh;
        font-size: 16px;
      }

      .download{
        padding: 8px 45px;
      }
    }
  }
}

@media screen and (max-width: 480px) and (orientation: landscape) {
  .header{

    &-content{

      .download{
        font-size: 18px;
      }

      .bottom-text{
        padding-top: 5px;

        p{
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-height: 568px) {
  .header{

    &-content{

      h1{
        font-size: 34px;
      }

      .sub-title{
        font-size: 17px;
      }
    }
  }

  .menu{

    &-nav{
      li.menu-item{
        margin-bottom: 6.6666666666666666666666666666667vh;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .header{
    // height: 125vh;

    &-content{

      .inner{
        
        .features{
          // display: none;
        }

        .bottom-text{
          // display: none;
        }
      }
    }
  }
  .menu-nav{
    max-width: 100%;
    .lang{
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 320px) and (orientation: portrait){
  .header{

    & > .wrap{
      height: calc(100% - 79px);
    }

    .top-slider{
      .slide-box{
        & > li{
          height: calc(100vh - 83px);
        }
      }
    }

    .slide-box{
      overflow: hidden;
    }

    &-content{
      .bottom-text{
        p{
          font-size: 13px;
          line-height: 1;
        }
      }
    }

  }

}

@media screen and (max-width: 320px) and (orientation: portrait){
  .header{

    & > .wrap{
      height: calc(100% - 79px);
    }

    .top-slider{
      .slide-box{
        & > li{
          height: calc(100vh - 83px);
        }
      }
    }

  }

}

/*  iPhone4  */
@media screen and (max-width: 320px) and (max-height: 480px){
  .header{

    &-content{

      .inner{
        h1{
            font-size: 30px;
        }
      }

      .download{
        margin: 3.2vh 0;
      }

      .features{
        li{
          font-size: 14px;
        }
      }

      .bottom-text{
        p{
          padding-top: 0;
        }
      }
    }

  }

}

@media screen and (max-width: 320px) {
  .header{
    &-content{

      .inner{
        
        h1{
          font-size: 38px;
        }
        .download{
          padding: 15px 35px;
        }
      }
    }

    // .page &{

    //   &-top{
    //     padding: 12px 0;
    //   }
    // }
  }

  // .menu-nav{
  //   top: -12px;
  // }
}


@media screen and (max-height: 480px) {
  .header{
    
    &-content{
      .inner{
        h1{
          font-size: 30px;
        }
      }
    }
  }
}

@media screen and (max-height: 320px) {
  .header{
    // height: 180vh;
  }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

  .header{

    &-content{

      .inner{
        text-align: center;

        h1{
        }
      }
    }
  }

}