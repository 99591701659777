/* === Login-page Main module === */

.page-login{
  background-color: #fff;
}

.login{

  &-bgc{
    background-color: #fff;
  }

    &-block{
      width: 85%;
      margin: 0 auto 7%;
      padding: 4.6%;
      background-color: #efefef;
      text-align: center;
      border-radius: 9px;
    }

    .outer{
      max-width: 240px;
      margin: 0 auto;

      a{
        display: inline-block;
        margin-bottom: 15px;
        font-family: "helveticaneuecyr-roman", sans-serif;
        font-size: 16px;
        color: #000;
      }
    }

    h1{
      margin-bottom: 20px;
      font-family: $ffr;
      font-size: 24px;
      line-height: 55px;
    }

    label{
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
      font-family:  $ffl;
      font-size: 16px;
      text-align: left;
    }

    input{
      width: 100%;
      margin-bottom: 20px;
      padding: 8px 12px;
      border: 1px solid #000;
      background-color: transparent;
      border-radius: 9px;

      &:focus{
        border: 1px solid $color;
        outline: none;
      }
    }

    button{
      min-width: 240px;
      width: 24%;
      padding: 8px 12px;
      font-family: $ffr;
      font-size: 16px;
      color: #fff;
      border-radius: 9px;
      border: none;
      background-color: $color;
    }

    &-popup{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 500px;
      padding: 40px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #999;
      border-radius: 9px;

      &-bg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .25);

        display: none;
      }

      p{
        font-family: $ffr;
        font-size: 22px;
        line-height: 1.2;
      }

      button{
        margin-top: 25px;
        padding: 10px 20px;
        font-family: $ffr;
        font-size: 16px;
        color: #fff;
        border-radius: 9px;
        border: none;
        background-color: $color3;
      }
    }
}