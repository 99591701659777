/* === FAQ-page Main module === */

.faq{
  padding-top: 48px;
  padding-bottom: 70px;

  h1{
    font-family: $ffr;
    font-size: 24px;
  }

  .not-find{
      @include span(8 of 12 0);

      padding-bottom: 30px;
      font-family: $ffr;
      font-size: 18px;

      p{
        display: inline-block;
        margin-right: 20px;
      }

    .btn-help{
      padding: 16px 62px;
      font-family: $ffr;
      font-size: 18px;
      color: #fff;
      background-color: $color;
      border: none;
      border-radius: 9px;
    }
  }

  .search-faq{
    @include span(4 of 12 0);

    margin-top: 5px;
    border-radius: 9px;
    overflow: hidden;

    .input-outer{
      box-sizing: border-box;
      display: inline-block;
      width: 450px;
      max-width: calc(100% - 35px);
      padding: 10px;
      border: 1px solid #000;
      border-radius: 9px;
      outline: none;
      background-color: #fff;
    }

    input{
      width: 100%;
      font-family: $ffl;
      font-size: 18px;
      border: none;
    }

    .btn-search{
      font-size: 20px;
      background: none;
      border: none;

      &:focus{
        outline: none;
      }
    }
  }

  .search-results{
    clear: both;
  }

  .sr{

    &-item{
      padding: 15px 0;
      position: relative;

      &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 1px;
        background-color: #acacac;
      }

      &:last-of-type:after{
        display: none;
      }

      a{
        color: #000;
      }
    }

    &-platform{
      font-family: $ffl;
      font-size: 14px;
      text-transform: uppercase;
      color: $color;
    }

    &-title{
      padding-bottom: 5px;
      font-family: $ffr;
      font-size: 18px;
    }

    &-descr{
      font-family: $ffl;
      font-size: 15px;
    }
  }

  .content-outer{
    @include container();

    background-color: #fff;
    border: 1px solid #acacac;
    border-radius: 9px;
    clear: both;
  }

  .col-tabs{
    @include span(3 of 12 0);

    ul{
      margin-top: 15px;
    }

    li{
      padding: 10px 15px;
      font-family: $ffr;
      font-size: 18px;
      line-height: 1.2;
      cursor: default;

      &.active-tab, &:hover{
        color: #fff;
        background-color: $color;
      }
    }
  }

  .col-answers{
    @include span(9 of 12 0);

    padding: 15px 28px;
    border-left: 1px solid #acacac;

    & div[class^="tab-content"]:not(:first-of-type){
      display: none;
    }
  }

  .question{
    padding: 15px 0px 13px;
    font-family: $ffl;
    font-size: 21px;
  }

  .answer{
    padding-bottom: 15px;
    font-family: $ffl;
    font-size: 16px;
    line-height: 1.2;
    border-bottom: 1px solid #efefef;

    &:last-of-type{
      border-bottom: none;
    }

    ul{
      list-style: disc;
      padding-left: 18px;
    }

    li{
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .mobile{
    display: none;
  }
}

.faq-popup{
  position: absolute;
  top: 20vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  min-width: 480px;
  padding: 5vw 4vw;
  background-color: #fff;
  border: 1px solid #acacac;
  border-radius: 9px;
  transition: top .6s, bottom .6s;

  &-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, .2);
    overflow: scroll;
    z-index: 3;

    display: none;
  }

  .text{
    margin-bottom: 10px;
    font-family: $ffr;
    font-size: 16px;
    line-height: 1;
    color: #000;

    &.margin{
      margin-bottom: 35px;
    }

    a{
      color: #0ba3ec;
      text-decoration: underline;
    }
  }

  .os{
    margin-bottom: 20px;
    border: 1px solid #acacac;
    border-radius: 9px;
    overflow: hidden;

    &:after{
      content: '';
      display: table;
      clear: both;
    }

    input{
      display: none;
    }

    label{
      float: left;
      box-sizing: border-box;
      width: 25%;
      padding: 10px 0;
      font-family: $ffr;
      text-align: center;
      border-right: 1px solid #acacac;

      .fa{
        width: 100%;
        margin-bottom: 5px;
      }

      &:last-of-type{
        border-right: none;
      }
    }

    input:checked+label{
      background-color: $color;
      color: #fff;
    }
  }

  .radio{
    margin-bottom: 20px;

    input{
      display: none;
    }

    label{
      display: inline-block;
      width: 100%;
      margin-bottom: 5px;
      font-family: $ffl;
      color: #aaafae;
    }

    .circle{
      display: inline-block;
      margin-bottom: -3px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #acacac;
      padding: 2px;

      &:before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: tr;
      }
    }

    input:checked+label{
      color: #000;

      & .circle:before{
        background-color: $color;
      }
    }
  }

  .field{
    float: left;
    width: 100%;
    margin-bottom: 15px;

    input, textarea{
      box-sizing:border-box;
      width: 100%;
      padding: 10px;
      border: 1px solid #acacac;
      border-radius: 9px;

      &:focus{
        border: 1px solid $color;
        outline: none;
      }
    }

    textarea{
      height: 74px;
    }

    label{
      display: inline-block;
      width: 100%;
      margin-bottom: 7px;
      font-family: $ffr;
    }

    &.half-width{
      width: 45%;

      &.to-right{
        float: right;
      }
    }

    .error{
      border: 1px solid #ea4848;
    }
    .inmail-error{
      display: inline-block;
      width: 100%;
      font-family: $ffl;
      font-size: 12px;
      color: #ea4848;
      text-align: right;

      display: none;
    }
    .error+.inmail-error{
      height: 0;
      display: block;
    }
  }

  .file{
    clear: both;
    font-family: $ffl;

    input[type="file"]{
      display: none;
    }

    label p{
      margin-top: 5px;

      & span{
        font-weight: bold;
        color: $color;
      }
    }
  }

  .btn-send{
    display: block;
    margin: 30px auto 0;
    padding: 8px 85px;
    font-family: $ffr;
    font-size: 16px;
    color: #fff;
    background-color: #ffa726;
    border: none;
    border-radius: 9px;

    display: none;
  }

  .hidden, .questions{
    display: none;
  }
}

.modal-help{
  position: relative;
  margin: 30vw auto;
  width: 450px;
  max-width: 90%;
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px #999;
  border-radius: 9px;

  @media screen and (max-width: 380px){
    &{
      padding: 50px 25px;
    }
  }

  &-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .35);
  }

  .title{
    margin: 5px 0 15px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 30px;
    line-height: 1;
    text-align: center;
    color: $color;
  }

  .text{
    margin-bottom: 5px;
    font-family: "helveticaneuecyr-roman", sans-serif;
    font-size: 21px;
    text-align: center;
  }

  .close{
    position: absolute;
    top: 15px;
    right: 15px;
    color: #acacac;
  }
}

@media screen and (max-width: 800px){

  .faq-popup{
    padding: 5vw;
  }

}

@media screen and (max-width: 800px){

  .faq{
    padding-top: 18px;
    padding-bottom: 18px;

    h1{
      text-align: center;
      margin-bottom: 10px;
    }

    .not-find{
      position: fixed;
      bottom: 0%;
      left: 0;
      width: 100%;
      padding-top: 30px;
      text-align: center;
      background-color: rgba(255, 255, 255, .9);
      z-index: 1;

      display: none;

      p{
        margin-right: 0;
        margin-bottom: 10px;
        font-size: 24px;

        span{
          display: none;
        }
      }
    }

    .search-faq{
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 9vw;

      .input-outer{
        max-width: 100%;
        width: 100%;
        border: none;

        input{
          box-sizing: border-box;
          padding: 10px;
          border: 1px solid #000;
          border-radius: 9px;
        }
      }

      .btn-search{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .content-outer{
    display: none;
  }

  .mobile{

    .faq &{
      display: block;
    }

    .tab-outer{
      margin-top: 20px;
      background-color: #fff;
      border: 1px solid #acacac;
      border-radius: 9px;
      overflow: hidden;
    }

    .tab{
      position: relative;
      padding: 20px 16px;
      padding-right: 44px;
      font-family: $ffr;
      font-size: 28px;
      background-color: $color;
      color: #fff;

      .fa{
        position: absolute;
        top: 50%;
        margin-top: -14px;
        right: 20px;
        transition: transform .4s;

        &.rotated{
          transform: rotate(-180deg);
        }
      }
    }

    .tab-content{
      display: none;
    }

    .question{
      padding-left: 16px;
      padding-right: 16px;
    }

    .answer{
      padding-left: 32px;
      padding-right: 16px;
    }
  }
}

@media screen and (max-width: 480px){

  .faq-popup{
    top: 10vw;
    min-width: 320px;

    .os{
      label{
        float: none;
        display: block;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #acacac;

        &:last-of-type{
          border-bottom: none;
        }

        .fa{
          width: auto;
        }
      }
    }

    .field{
      &.half-width{
        width: 100%;
      }
    }
  }

}