/* === Packages Cabinet module === */

.packages{

  section{
    padding-left: 3.96%;
    text-align: center;
    position: relative;

    h2{
      padding: 5.5% 0 2.4%;
      font-family: $ffr;
      font-size: 24px;
      text-align: left;
    }

    h3{
      padding: 4.18% 1.14%;
      font-family: $ffr;
      font-size: 18px;
      text-align: left;
    }
  }

  .search-tariff{
    width: 100%;
    background-color: #efefef;

    text-align: center;
  }

  .drop-outer{
    display: inline-block;
    width: 450px;
    margin-left: -35px;
    // position: relative;

    .drop{
      max-width: 100%;
      margin-top: 48px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .drop{
    left: 50%;
    transform: translateX(-50%);
  }

  .result{
    max-width: 485px;
    margin: 0 auto;

    .result-item{
      position: relative;

      &:after{
        content: '';
        display: table;
        clear: both;
      }
    }
  }

  .buy{
    padding: 7px 40px;
    font-family: $ffm;
    font-size: 21px;
    color: #fff;
    background-color: #ffa726;
    border: none;
    border-radius: 9px;
  }


  .country{
    background-color: #fff;
    border-bottom: 1px solid #efefef;

    &-name{
      top: 20px;
      font-family: $ffl;
      font-size: 21px;
      line-height: 19px;
      color: #000;
    }

    img{
      height: 38px;
    }
  }

  .row{
    padding-bottom: 7%;

    &:after{
      content: '';
      display: table;
      clear: both;
    }
  }

  .elem{
    margin-bottom: 1.99%;
    box-shadow: 0px 0px 17px -2px #cfcfcf;

    .buy{
      padding: 10px 62px;
      transition: background-color .2s;

      &:hover{
        background-color: $color;
      }
    }
  }

  .price{
    font-size: 50px;

    &-old{
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 600px) {
  
  .packages section{
    padding-left: 10px;
    padding-right: 10px;

    h3{
      text-align: center;
    }
  }

}