/* === Footer module === */

.footer{
  clear: both;

  .wrap{
    padding-top:20px;
    padding-bottom: 20px;
    border-top: 1px solid #acacac;
  }

  .col{

    &-1{
      float: left;
    }

    &-2{
      float: right;

      text-align: right;
    }
  }

  .social{

    li{
      float: left;
      margin-right: 15px;

      a{
        display: inline-block;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        box-shadow: 0 0 10px 1px transparent;
        transition: box-shadow .2s;

        &:hover{
          box-shadow: 0 0 10px 1px #000;
        }
      }

      .fb{
        background: #1f599b url(../img/soc_fb.png) center no-repeat;
      }

      .tw{
        background: #00adf2 url(../img/soc_tw.png) center no-repeat;
      }

      .g-plus{
        background: #dc2d00 url(../img/soc_g-plus.png) center no-repeat;
      }

      .vk{
        background: #25668c url(../img/soc_vk.png) center no-repeat;
      }
    }
  }

  .actual-links{
    font-size: 14px;
    a{
      font-family: $ffr;
      color: #757575;
    }
    .disc{
      position: relative;
      top: 3px;
      font-size: 1.5em;
      opacity: .4;
    }
  }

  .copy-author{
    padding-top: 10px;
    p{
      font-family: $ffr;
      font-size: 14px;
      color: #757575;
      opacity: .75;

      img{
        margin-bottom: -5px;
      }

      a{
        color: #757575;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer{
    padding: 0 10px;
  }
}


@media screen and (max-width: 600px) {
  .footer{

    .col{
      width: 100%;

      &-2{
        text-align: center;
      }
    }

    .social{
      ul{
        text-align: justify;

        &:after{
          content: '';
          display: inline-block;
          width: 100%;
        }
      }
      li{
        display: inline-block;
        float: none;

        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .footer{

    .copy-author p{
      font-size: 13px;
    }
  }
}