/* === Profile Cabinet module === */

.profile{

  section{
    padding-left: 3.96%;

    h2{
      padding: 5.5% 0 2.4%;
      font-family: $ffr;
      font-size: 24px;
      text-align: left;
    }
  }

  .content{
    margin-bottom: 12.5%;
    padding: 6.4% 0;
    background-color: #efefef;
    border-radius: 9px;

    .inner{
      position: relative;

      &:after{
        content: '';
        display: table;
        clear: both;
      }
    }
  }
}

.ava{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  float: left;
  width: 36.9%;
  text-align: center;

  input[type="file"]{
    display: none;
  }

  .img-outer{
    display: inline-block;
    max-width: 187px;
    max-height: 187px;
    border-radius: 50%;
    overflow: hidden;

    label{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      display: none;
    }
  }

  img{
    display: block;
    max-width: 100%;
  }

  &.edit-mode{

    .img-outer{
      position: relative;
      display: inline-block;

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .4) url(../img/cabinet/avatar_active_edit.png) center no-repeat;
        background-size: 50px;
        border-radius: 50%;
      }
    }
  }
}

.info{
  float: right;
  width: 63%;
  padding-left: 8%;
  // padding-left: 11%;
  border-left: 1px solid #acacac;


  .line{
    font-family: $ffr;
    font-size: 18px;
    line-height: 40px;

    &-name{
      float: left;
      width: 42%;
      color: $color;
    }

    &-value{
      float: right;
      width: 55%;
      color: #000;
      white-space: nowrap;
      text-rendering: auto;
      text-overflow: ellipsis;
      overflow: hidden;

    }

    input{
      width: 100%;
      padding: 0;
      font-size: 19px;
      box-sizing: border-box;
      border: none;
      outline: none;
      background-color: transparent;

      display: none;
    }

    label{
      width: auto;
      display: inline-block;
      margin-right: 15px;
      line-height: 1;

      span{
        font-size: 16px;
        line-height: 1;
      }

      display: none;
    }

    &.pass{
      .line-name{
        display: none;
      }
    }

    &.edit-mode.pass{
      .line-name{
        display: block;
      }
    }

    &.error{
      position: relative;

      &.attention{
        -webkit-animation: inputFlash .5s ease-in forwards;
                animation: inputFlash .5s ease-in forwards;
      }

      input[type="text"], input[type="email"]{
        border: 1px solid #ea4848!important;
      }

      .error-msg{
        display: block;
        height: 14px;
      }
    }

    .error-msg{
      position: absolute;
      bottom: 6px;
      right: 0;
      padding-right: 30px;
      font-family: $ffl;
      font-size: 12px;
      color: #ea4848;
      text-align: right;

      display: none;
    }
  }
  @-webkit-keyframes inputFlash {
    0%{
      box-shadow: 0px 0px 0px -2px coral;
    }
    33.3%{
      box-shadow: 0px 0px 12px -2px coral;
    }
    66.6%{
      box-shadow: 0px 0px 0px -2px coral;
    }
    100%{
      box-shadow: 0px 0px 12px -2px coral;
    }
  }
  @keyframes inputFlash {
    0%{
      box-shadow: 0px 0px 0px -2px coral;
    }
    33.3%{
      box-shadow: 0px 0px 12px -2px coral;
    }
    66.6%{
      box-shadow: 0px 0px 0px -2px coral;
    }
    100%{
      box-shadow: 0px 0px 12px -2px coral;
    }
  }

  .btns{
    text-align: left;
    clear: both;

    .btn{
      cursor: default;
    }
  }

  .btn-edit{
    display: inline-block;
    margin-top: 13px;
    padding: 9px 80px;
    font-family: $ffr;
    font-size: 16px;
    color: #fff;
    background-color: $color;
    border-radius: 9px;
  }

  .btn-save{
    display: inline-block;
    margin-top: 13px;
    padding: 9px 80px;
    font-family: $ffr;
    font-size: 16px;
    color: #fff;
    background-color: $color;
    border: none;
    border-radius: 9px;

    display: none;
  }

  .btn-cancel{
    display: inline-block;
    margin-top: 13px;
    margin-right: 30px;
    padding: 9px 10px 9px 0;
    font-family: $ffr;
    font-size: 16px;
    color: #dc2d00;

    display: none;
  }
}

@media only screen and (min-width: 1000px) {


  .info{

    .line{

      &.edit-mode{
        width: 50%;
        float: left;

        .line-name{
          width: 100%;
          font-size: 16px;
          color: #000;
        }

        .line-value{
          &.not-edit{
            width: 100%;
            font-size: 16px;
            line-height: 1;
            text-align: left;
          }
        }

        &.pass{
          .line-name{
            display: block;
          }
        }
      }

      input[type="text"], input[type="email"], input[type="password"]{
        width: calc(100% - 30px);
        margin-bottom: 10px;
        padding: 5px 7px;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 9px;
        font-size: 16px;

        &:focus{
          border: 2px solid $color;
        }
      }
    }
  }

  .ava{

    &.edit-mode{
      label{
        display: block;
      }
    }
  }

}

@media only screen and (max-width: 1000px) {

  .profile{

    section{
      padding: 0 10px;

      h2{
        font-size: 26px;
        text-align: center;
        color: $color;
      }
    }

    .content{
      background-color: transparent;
    }
  }

  .ava{
    position: static;
    width: 100%;
    transform: translateY(0);
  }

  .info{
    width: 100%;
    margin-top: 15px;
    padding-left: 0;
    border-left: none;

    .line{
      margin-bottom: 15px;
      padding: 14px;
      line-height: 1;
      border: 1px solid #acacac;
      border-radius: 9px;

      &.pass{
        display: none;
      }

      &.edit-mode{
        &.pass{
          display: block;
        }
      }

      &:after{
        content: '';
        display: table;
        clear: both;
      }

      &-name{
        font-size: 16px;
        color: #4b4b4b;
      }

      &-value{
        font-size: 19px;
        text-align: right;
      }

      &.error{
        border-color: coral;
        box-shadow: 0px 0px 12px -2px coral;

        &.attention{
          -webkit-animation: inputFlash .5s ease-in forwards;
                  animation: inputFlash .5s ease-in forwards;
        }
      }
    }

    .btns{
      text-align: center;
    }

    .btn-save, .btn-edit{
      padding: 15px 40px;
      font-size: 20px;
    }

    .btn-cancel{
      margin-right: 15px;
      padding: 9px 10px;
      font-size: 20px;
    }
  }

}