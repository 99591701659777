/* === Main module === */

.platform{
  padding-top: 60px;
  padding-bottom: 70px;

  h2{
    font-family: $ffr;
    font-size: 30px;
    line-height: 55px;
    text-align: center;

    & > span{
      font-family: $ffa;
      font-weight: bold;
    }
  }

  .row{
    @include container(12 0);

    margin-top: 35px;
    padding: 30px 0;
    border-radius: 9px;
    overflow: hidden;

    &-1{
      background-color: #63b832;
    }

    &-2{
      background-color: #0ba3ec;
    }

    .elem{
      @include span(6 of 12 0);
    }
  }

  &-win, &-mac{
    .device-img{
      min-height: 177px;
      min-width: 330px;
      float: left;
      margin-top: -7px;
      margin-bottom: -5px;
      margin-left: -100px;
    }
  }

  &-win{
    .device-img{
      background: transparent url(../img/inst-win.png) center no-repeat;
    }
  }

  &-mac{
    .device-img{
      background: transparent url(../img/inst-mac.png) center no-repeat;
    }
  }

  &-android, &-ios{
    .device-img{
      min-height: 175px;
      min-width: 99px;
      float: left;
      margin-left: 25px;
      margin-right: 15px;
      margin-bottom: -15px;
    }
  }

  &-android{
    .device-img{
      background: transparent url(../img/inst-android.png) center no-repeat;
    }
  }

  &-ios{
    .device-img{
      background: transparent url(../img/inst-ios.png) center no-repeat;
    }
  }

  &-title{
    font-family: $ffr;
    font-size: 21px;
    color: #fff;
  }

  &-android{
    .dl-btn{
      p{
        width: 100%;
        height: 100%;
        background: transparent url(../img/google-play.png) center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-ios{
    .dl-btn{
      p{
        width: 100%;
        height: 100%;
        background: transparent url(../img/btn_dl-ios.png) center no-repeat;
        background-size: 100%;
      }
    }
  }

  .qr-code{
    float: right;
    margin-top: 5px;
    margin-right: 30px;
  }

  .bottom-text{
    margin-top: 45px;
    font-family: $ffr;
    font-size: 14px;
    color: #fff;
  }
}


@media screen and (max-width: 800px) {
  .platform{
    padding-top: 0;

    &-title{
      margin-top: 7.5vw;
      // float: left;
      font-size: 7.5vw;
      text-align: center;
    }

    h2{
      margin: 15px 0;
      // font-size: 36px;
      line-height: 1.1;
    }
    .row{
      @include container(12 0);

      margin-top: 0px;
      padding: 0;
      border-radius: 9px;
      overflow: hidden;

      &-1{
        background-color: transparent;
        .elem{
          background-color: #63b832;
        }
      }

      &-2{
        background-color: transparent;
        .elem{
          background-color: #0ba3ec;
        }
      }

      .elem{
        @include span(12 of 12 0);
        margin-bottom: 10px;
        padding: 35px 0;
        border-radius: 5px;
      }

      .qr-code, .bottom-text{
        display: none;
      }
    }

    .dl-btn-outer{
      display: block;
      clear: both;
      text-align: center;
    }
  }
}


@media screen and (max-width: 480px) {
  .platform{

    &-win, &-mac{
      .device-img{
        min-height: 177px;
        min-width: 330px;
        float: left;
        margin-top: -7px;
        margin-bottom: -5px;
        margin-left: -129px;
      }
    }
  }
}


@media screen and (max-width: 380px) {
  .platform{

    &-title{
      margin-top: 12.5vw;
      position: relative;
      right: 15px;
    }
  }
}