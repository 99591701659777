/* font-family: "Helvetica  Roman"; */
@font-face{
    font-family: 'helveticaneuecyr-roman-webfont';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/helveticaneuecyr-roman-webfont.eot');
    src: url('../fonts/helveticaneuecyr-roman-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helveticaneuecyr-roman-webfont.woff2') format('woff2'),
    url('../fonts/helveticaneuecyr-roman-webfont.woff') format('woff'),
    url('../fonts/helveticaneuecyr-roman-webfont.ttf') format('truetype');
}

/* font-family: "Helvetica  Light"; */
@font-face{
    font-family: 'helveticaneuecyr-light-webfont';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/helveticaneuecyr-light-webfont.eot');
    src: url('../fonts/helveticaneuecyr-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helveticaneuecyr-light-webfont.woff2') format('woff2'),
    url('../fonts/helveticaneuecyr-light-webfont.woff') format('woff'),
    url('../fonts/helveticaneuecyr-light-webfont.ttf') format('truetype');
}

/* font-family: "Helvetica  Medium"; */
@font-face{
    font-family: 'helveticaneuecyrmedium';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/helveticaneuecyr-medium-webfont.eot');
    src: url('../fonts/helveticaneuecyr-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helveticaneuecyr-medium-webfont.woff2') format('woff2'),
    url('../fonts/helveticaneuecyr-medium-webfont.woff') format('woff'),
    url('../fonts/helveticaneuecyr-medium-webfont.ttf') format('truetype');
}

/* font-family: "AllerRegular"; */
@font-face {
    font-family: 'AllerRegular';
    src: url('../fonts/aller_rg-webfont.eot');
    src: url('../fonts/aller_rg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aller_rg-webfont.woff2') format('woff2'),
         url('../fonts/aller_rg-webfont.woff') format('woff'),
         url('../fonts/aller_rg-webfont.ttf') format('truetype'),
         url('../fonts/aller_rg-webfont.svg#allerregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* THis font for right arrow icon */
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?22086859');
  src: url('../fonts/fontello.eot?22086859#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff?22086859') format('woff'),
       url('../fonts/fontello.ttf?22086859') format('truetype'),
       url('../fonts/fontello.svg?22086859#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}